.btn {
  position: absolute;
  background-color: #fff;
  z-index: 9;
}

.done-btn {
  top: 52px;
  left: 50%;
  transform: translateX(calc(50% - 64px));
}

.btn-treatment-filled {
  background-color: rgb(2, 101, 220);
}
