.popover {
  position: relative;
  display: flex;
  width: var(--popover-width, 268px);
  flex-direction: column;
  gap: 6px;
  padding: 12px;
  border-radius: 8px;
  background: var(--Alias-background-app-frame-layer-1, #f8f8f8);
  box-shadow: 0px 2px 8px 0px var(--Palette-transparent-black-300);
  overflow: auto;

  h2 {
    padding: unset;
    margin: unset;
    color: var(
      --Alias-content-typography-heading,
      var(--Alias-content-typography-heading, #131313)
    );
    font-size: var(--popover-heading-font-size, 14.81px);
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }
}

.popover[data-input-size='s'] {
  ue-color-picker {
    --ue-color-picker-overlay-trigger-height: 24px;
  }
}

.popover[data-input-size='m'] {
  --popover-width: 298px;
  --popover-heading-font-size: 16px;
}

.styles-option {
  --mod-neo-selection-card-item-size: 100%;

  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 13px;
  padding: 12px 8px 0 8px;
}

.inputs-header {
  margin-bottom: 6px;
}
