.container {
  width: 100%;
  min-width: 100%;
  border-radius: var(--spectrum-global-dimension-size-125, 10px);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: var(--spectrum-spacing-200, 12px);

  h6,
  p {
    margin: unset;
    padding: unset;
    font-size: 14px;
    font-style: normal;
  }

  h6 {
    color: var(--Alias-content-typography-heading);
    font-weight: 700;
    line-height: 130%;
  }

  p {
    color: var(--Alias-content-typography-body);
    font-weight: 400;
    line-height: 150%;
    text-wrap: pretty;
  }
}

.neutral {
  background: var(--Alias-background-neutral-subtle-default);
}
