.badge {
  height: 24px;
  display: flex;
  gap: 4px;
  align-items: center;
  border-radius: 7px;
  background-color: var(--green-accent);
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  padding: 0 9px 0 5px;

  sp-icon {
    transform: scale(0.9);
    margin-top: -2px;
  }
}

.content-type-picker-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spectrum-global-dimension-size-100, 8px);
}

.reference-effects-container {
  --mod-neo-selection-card-item-size: 80px;

  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: var(
    --mod-firefly-panel-reference-effects-grid-template-columns,
    1fr 1fr 1fr
  );
  gap: var(--mod-firefly-panel-reference-effects-gap, 16px);
}

.popover {
  display: flex;
  width: 476px;
  height: 504px;
  padding: var(--spectrum-global-dimension-size-150, 12px)
    var(--spectrum-global-dimension-size-300, 24px)
    var(--spectrum-global-dimension-size-300, 24px)
    var(--spectrum-global-dimension-size-300, 24px);
  border-radius: var(--spectrum-global-dimension-size-150, 12px);
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.3);
  overflow: auto;
}

.popover-header {
  font-weight: var(--spectrum-global-font-weight-bold, 700);
  line-height: 130%;
}

.popover-content {
  display: flex;
  flex-direction: column;
  gap: var(--spectrum-global-dimension-size-200, 16px);
}

.popover-effect-header {
  font-size: var(--spectrum-font-size-100, 14px);
  font-weight: var(--spectrum-global-font-weight-bold, 700);
  line-height: 130%;
}

.thumbnail-container {
  position: relative;
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  border-radius: var(--icons-focus-radius, 12px);
  cursor: pointer;
  color: var(--Alias-content-neutral-default);

  &__style-reference-image-container {
    outline: 1px dashed var(--spectrum-gray-600);
  }

  &__style-effects-container {
    background-color: var(--Palette-gray-300);
  }

  &__icon-container {
    width: 56px;
    height: 56px;
  }

  &:hover {
    background-color: var(--Palette-gray-200);
  }

  &.selected {
    box-shadow: 0px 0px 0px 3px var(--Alias-content-semantic-accent-selected);
    outline: 1px solid var(--spectrum-global-color-gray-50);
  }
}

.popular-prompts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: var(--spectrum-global-dimension-size-150, 12px);
}

.action-btn {
  --mod-actionbutton-icon-size: 16px;
}

.selected-style-reference-clear-btn {
  --mod-actionbutton-min-width: 24px;

  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 9;
  height: 24px;
  width: 24px;
}

.selected-style-reference-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  width: 80px;

  img {
    height: 80px;
    width: 80px;
  }
}

.substance-3d-action-btn {
  width: 100%;
}

.terms {
  color: var(--Alias-background-semantic-accent-default);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-self: center;
}
