@import 'styles/variables';

.studio-app-bar {
  --content-gap: 8px;

  position: relative;
  background-color: var(--Alias-background-app-frame-layer-2);
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: all;

  ue-menu-item {
    min-width: 193px;
  }

  ue-menu-item ue-switch {
    --mod-switch-height: 24px;
    --mod-menu-item-label-to-value-area-min-spacing: 0px;
    --mod-switch-control-label-spacing: 0px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.duplicate-project-warning-dialog-base {
  z-index: 99999;
}

.duplicate-project-warning-dialog-footer {
  padding-top: 30px;
  gap: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.non-interactive {
  pointer-events: none !important;
}

.grouped-content {
  padding: 0 var(--content-gap);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--content-gap);
}

.app-logo {
  height: var(--mod-studio-app-bar-logo-size, 32px);
  width: var(--mod-studio-app-bar-logo-size, 32px);
}

.beta {
  padding: unset;
  margin: unset;
  font-size: var(--mod-studio-app-bar-body-font-size, 14px);
  font-style: normal;
  font-weight: 400;
  color: var(--Alias-content-neutral-default);
}

.project-name {
  padding: unset;
  margin: unset;
  color: var(--Alias-content-neutral-default);
  font-size: var(--mod-studio-app-bar-body-font-size, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
}

.scene-to-image-beta {
  font-weight: 700;
  font-size: 16px;
  line-height: 20.03px;
  color: var(--Alias-content-neutral-default);
}

.universal-nav-divider {
  margin: 0;
}
// This same breakpoint is used in CommunityAppBar.module.scss
@media (max-width: $breakpoint-medium) {
  .studio-app-bar {
    position: fixed;
    background-color: var(--Palette-gray-75);
    height: var(--studio-app-bar-height);
  }

  .grouped-content {
    padding: var(--content-gap) var(--content-gap);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--content-gap);
  }
}
.import-svg-button {
  position: relative;
}

.import-svg-input {
  position: absolute;
  visibility: hidden;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
