.input {
  width: 100%;
  border-radius: 7px;
  border: 2px solid var(--Palette-gray-300);
  background: var(--Palette-gray-25);
  color: var(--Palette-gray-800);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input:hover {
  border-color: var(--Palette-gray-400);
}

.input:focus-visible {
  border-color: var(--Palette-gray-900);
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--Palette-blue-800);
  outline-offset: 2px;
  color: var(--Palette-gray-900);
}

.size-s {
  height: 24px;
  font-size: 12px;
  padding: 0 8px;
}

.size-m {
  height: 32px;
  font-size: 15px;
  padding: 0 12px;
}

.size-l {
  height: 40px;
  font-size: 16px;
  padding: 0 14px;
}
