@import './spectrum-two/spectrum-two.scss';

:root {
  --onboarding-nav-height: 80px;

  --mod-actionbutton-border-radius: 8px;

  --mod-modal-confirm-border-radius: 16px;

  --app-bar-height: 60px;
  --drawer-width: 320px;
  // --rail-width: 64px;
  --toast-bottom-position: 87px;

  --green-accent: #07816d;
  --green-accent-dark: #1a7364;

  // Base components

  --side-navigation-item-width: 160px;

  // Studio page

  --studio-rail-width: 48px;
  --studio-app-bar-height: 56px;
  --studio-side-navigation-item-width: 160px;
  --studio-firefly-generated-image-height: 54px;
  --studio-app-bar-height: 56px;

  --spectrum-sans-serif-font-family: adobe-clean;
  --spectrum-cjk-font-family: adobe-clean-han-japanese;
  --spectrum-alias-font-family-ja: var(--spectrum-cjk-font-family);
  --spectrum-alias-font-family-ko: adobe-clean-han-korean;
}

:lang(ja) {
  font-family: var(--spectrum-alias-font-family-ja);
  --spectrum-font-family-base: var(--spectrum-alias-font-family-ja);
  --spectrum-alias-body-text-font-family: var(--spectrum-alias-font-family-ja);
  --spectrum-sans-font-family-stack: var(--spectrum-alias-font-family-ja);
}

:lang(ko) {
  font-family: var(--spectrum-alias-font-family-ko);
  --spectrum-font-family-base: var(--spectrum-alias-font-family-ko);
  --spectrum-alias-body-text-font-family: var(--spectrum-alias-font-family-ko);
  --spectrum-sans-font-family-stack: var(--spectrum-alias-font-family-ko);
}
