.rail {
  border-radius: 0;
  padding: 8px;
  width: 64px;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  background-color: var(--spectrum-global-color-gray-50);
  overflow-y: auto;
  position: relative;
}

.action-button {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  padding: 11px;
  border-radius: 10px;
  width: 48px;
  height: 48px;

  sp-icon {
    width: var(--menu-item-icon-size, 22px);
    height: var(--menu-item-icon-size, 22px);
  }

  &[data-icon-size='l'] {
    --menu-item-icon-size: 26px;
  }

  &[data-icon-size='m'],
  &[data-icon-size='s'] {
    --menu-item-icon-size: 22px;
  }
}
