@import 'styles/variables';

.container {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: var(--spectrum-global-dimension-size-200, 16px);
  background-color: var(--Alias-background-app-frame-layer-2);
  opacity: 1;
  transition: opacity 0.3s ease;
}

.content {
  height: 100%;
  overflow: hidden;
  border-radius: var(--spectrum-global-dimension-size-200, 16px);
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.action-button-wrapper {
  display: none;
}

.container {
  &:hover .action-button-wrapper {
    padding: 0 16px;
    display: flex;
    width: 100%;
    gap: var(--spectrum-global-dimension-size-100, 8px);
    position: absolute;
    justify-content: space-between;
    top: 16px;
    // right: 10px;
    z-index: 10000;
  }
}

.aspect-ratio-picker {
  position: absolute;
  left: 5px;
  top: 0;
  transform: translateY(-100%);

  --highcontrast-picker-border-color-hover: transparent;
  --highcontrast-picker-background-color: transparent;
}

.empty {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 40px;
  text-align: center;
  overflow: hidden;
  
  &__icon-container {
    width: 96px;
    height: 96px;
  }

  &__title {
    font-weight: 800;
    color: var(--Palette-gray-800);
    line-height: 129%;
    
    &--panel-closed {
      font-size: 24px;
      margin: 35px 0 0 0;
    }

    &--panel-opened {
      font-size: 18px;
      margin: 8px 0 0 0;
    }
  }

  &__body-text {
    font-weight: 400;
    font-style: normal;
    color: var(--Palette-gray-700);
    text-overflow: ellipsis;
    
    &--panel-closed {
      font-size: 16px;
      margin: 11px 0 0 0;
    }

    &--panel-opened {
      font-size: 14px;
      margin: 8px 0 0 0;
    }
  }

  @media (max-width: 1440px) {
    &__icon-container {
      &--panel-opened {
        width:  78px;
        height: 78px;
      }
    }

    &__title {
      display: none;
    }
    
    &__body-text {
      &--panel-opened {
        font-size: 16px;
        margin: 8px 0 0 0;
      }
    }
  }

  @media (max-width: $breakpoint-extra-large) {
    &__body-text {
      &--panel-closed {
        font-size: 16px;
        margin: 8px 0 0 0;
      }
  
      &--panel-opened {
        display: none;
      }
    }
  }

  @media (max-width: $breakpoint-large) {
    &__icon-container {
      &--panel-opened {
        width:  64px;
        height: 64px;
      }
    }

    &__body-text {
      display: none;
    }
  }

  @media (max-width: $breakpoint-medium) {
    &__icon-container {
      display: none;
    }
  }
}

.toast {
  position: fixed;
  top: calc(var(--studio-app-bar-height) + 12px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 999999;
}
