.close-button {
  position: absolute;
  top: 11px;
  right: 10px;
  z-index: 1;
}

.dialog {
  border-radius: 16px;
  overflow: hidden;
  z-index: 0;
  height: fit-content;
  width: 639px;
}

.card-container {
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  margin-top: calc(-1 * var(--mod-scroll-content-padding-top, 2px));
  display: grid;
  grid-template-rows: 388px auto;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-text-container {
  padding: 18px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
}

.card-text {
  text-align: left;
  margin: 0;
  color: var(--Palette-gray-900);
}

.card-title {
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
}

.card-body {
  margin-top: 2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17.53px;
}

.to-neo-button {
  align-self: flex-start;
  margin-top: 16px;
}
