@import 'styles/variables';

.container {
  position: relative;
  width: 100%;
  height: var(--onboarding-nav-height);
  position: fixed;
  z-index: 9998;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--spectrum-global-color-gray-50);
  border-bottom: 1px solid #c2c2c2;
}

.nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--spectrum-global-dimension-size-200, 18px);
}

.hide {
  display: none;
}

.hamburger-menu {
  display: none;
}

.side-nav {
  display: none;
  position: absolute;
  z-index: 5;
  height: calc(100vh - var(--onboarding-nav-height));
  top: var(--onboarding-nav-height);
  left: 0;
  background-color: var(--spectrum-global-color-gray-50, #fff);
  overflow-y: hidden;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
}

.side-nav-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 3.5);
}

.close-icon {
  padding: var(--spectrum-global-dimension-size-300, 24px) 0
    var(--spectrum-global-dimension-size-300, 24px)
    var(--spectrum-global-dimension-size-150, 12px);
  margin-top: 44px;
}

.side-nav-links {
  display: flex;
  flex-direction: column;
  gap: var(--spectrum-global-dimension-size-100, 8px);
  padding: var(--spectrum-global-dimension-size-200, 16px);

  span {
    cursor: pointer;
    font-size: var(--spectrum-font-size-100, 14px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0 var(--spectrum-global-dimension-size-200, 16px);
    height: var(--spectrum-global-dimension-size-400, 32px);
    display: flex;
    align-items: center;
    border-radius: var(--spectrum-global-dimension-size-100, 8px);
  }
}

.side-nav-action-buttons {
  display: flex;
  flex-direction: column;
  gap: var(--spectrum-global-dimension-size-200, 16px);
  padding: var(--spectrum-global-dimension-size-300, 24px);
  margin-bottom: 10px;
  align-items: flex-start;
}

.side-nav-overlay {
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: var(--onboarding-nav-height);
  left: 0;
  z-index: 1;
  transition: opacity 0.5s ease, visibility 0.5s ease;

  &__show {
    visibility: visible;
    opacity: 1;
  }
}

.logo {
  display: flex;
  align-items: center;
  gap: var(--spectrum-global-dimension-size-125, 10px);
}

.nav-action-buttons {
  display: flex;
  align-items: center;
  gap: var(--spectrum-global-dimension-size-100, 8px);
}

.discord-button {
  margin-right: 8px;
  border-radius: 50%;
  background-color: #e9e9e9;

  &:hover {
    background-color: #d9d9d9;
  }
}

.user-profile-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spectrum-global-dimension-size-50);
}

.nav-content {
  display: flex;
  align-items: center;
  gap: var(--spectrum-global-dimension-size-400, 32px);
}

.nav-links {
  display: flex;
  align-items: center;
  gap: var(--spectrum-global-dimension-size-300, 24px);

  span {
    cursor: pointer;
    font-size: var(--spectrum-font-size-100, 14px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

@media (max-width: $breakpoint-medium) {
  .hamburger-menu {
    display: block;
  }

  .nav-content {
    gap: var(--spectrum-global-dimension-size-150, 12px);
  }

  .side-nav {
    display: block;
    z-index: 2;
  }

  .side-nav-links {
    white-space: nowrap;
  }

  .action-buttons {
    display: none;
  }

  .nav-links {
    display: none;
  }
}
