.wrapper {
  --selection-card-item-size: 80px;
  --selection-card-item-border-size: 2px;
  --selection-card-item-border-radius: 12px;

  display: flex;
  flex-direction: column;
  gap: var(--spectrum-global-dimension-size-50, 4px);
  position: relative;
  width: var(--selection-card-item-size, 80px);
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  user-select: none;
  outline: 0;
  width: var(--mod-neo-selection-card-item-size, 100%);
}

.image-container {
  width: 100%;
  height: var(--mod-neo-selection-card-item-size, --selection-card-item-size);
  aspect-ratio: 1/1;
  display: flex;
  background: var(--spectrum-global-color-gray-200);
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--selection-card-item-border-radius);
  overflow: hidden;
  border: var(--selection-card-item-border-size) solid transparent;
  border-radius: var(--selection-card-item-border-radius);

  img {
    border-radius: var(--selection-card-item-border-radius);
  }
}

.image-full-size {
  height: 100%;
  width: 100%;
}

.label {
  padding: 0 var(--spectrum-global-dimension-size-50, 4px)
    var(--spectrum-global-dimension-size-50, 4px)
    var(--spectrum-global-dimension-size-50, 4px);
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--Alias-content-neutral-subdued-default);
  font-size: var(--spectrum-font-size-75, 12px);
  font-weight: var(--spectrum-global-font-weight-regular, 400);
  text-align: center;
}

.selected {
  .image-container {
    border: var(--selection-card-item-border-size) solid
      var(--Alias-content-semantic-accent-selected);
    position: relative;
  }

  .image-container::after {
    box-sizing: border-box;
    border: 1px solid var(--spectrum-global-color-gray-100);
    border-radius: var(--selection-card-item-border-radius);
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.checkmark-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--spectrum-global-dimension-size-200, 16px);
  width: var(--spectrum-global-dimension-size-200, 16px);
  position: absolute;
  top: var(--spectrum-global-dimension-size-75, 6px);
  right: var(--spectrum-global-dimension-size-75, 6px);
  border-radius: var(--spectrum-corner-radius-100, 4px);
  background: var(--Alias-content-semantic-accent-selected);

  svg {
    color: var(--Palette-gray-25);
  }
}

.close-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--spectrum-global-dimension-size-300, 24px);
  width: var(--spectrum-global-dimension-size-300, 24px);
  display: none;
  position: absolute;
  top: var(--spectrum-global-dimension-size-75, 6px);
  right: var(--spectrum-global-dimension-size-75, 6px);
  border-radius: 7px;
  background: var(--spectrum-global-color-gray-800);
  color: var(--spectrum-global-color-gray-50);
}

.container:hover .close-icon {
  cursor: pointer;
  display: flex;
}
