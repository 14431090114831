.typography {
  font-size: unset;
  font-weight: normal;
  line-height: unset;
  margin-block: unset;
  margin-inline: unset;
  color: unset;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: var(--spectrum-heading-color, #000);
  font-weight: var(--spectrum-heading-sans-serif-font-weight);
  line-height: var(--spectrum-heading-line-height);
}

.h1 {
  font-size: var(--spectrum-heading-size-xl);
}

.h2 {
  font-size: var(--spectrum-heading-size-l);
}

.h3 {
  // Design uses 20px instead of --spectrum-heading-size-m variable
  font-size: 20px;
}

.h4 {
  font-size: var(--spectrum-heading-size-s, 18px);
}

.h5 {
  font-size: var(--spectrum-heading-size-xs);
}

.h6 {
  font-size: var(--spectrum-heading-size-xxs);
}

.disabled {
  color: var(--spectrum-disabled-content-color, rgb(177, 177, 177));
}
