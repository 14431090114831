sp-theme[color='light'] {
  /*
    Colors
  */

  --Palette-gray-25: #fff;
  --Palette-gray-50: #f8f8f8;
  --Palette-gray-75: #f3f3f3;
  --Palette-gray-100: #e9e9e9;
  --Palette-gray-200: #e1e1e1;
  --Palette-gray-300: #dadada;
  --Palette-gray-400: #c6c6c6;
  --Palette-gray-500: #8f8f8f;
  --Palette-gray-600: #717171;
  --Palette-gray-700: #505050;
  --Palette-gray-800: #292929;
  --Palette-gray-900: #131313;
  --Palette-gray-1000: #000;

  --Palette-blue-300: #cbe2fe;
  --Palette-blue-800: #4b75ff;
  --Palette-blue-900: #3b63fb;
  --Palette-blue-1000: #274dea;

  --Palette-transparent-black-200: rgba(0, 0, 0, 0.12);
  --Palette-transparent-black-300: rgba(0, 0, 0, 0.15);

  --Alias-background-neutral-selected-default: var(--Palette-gray-800);
  --Alias-background-neutral-selected-hover: var(--Palette-gray-900);
  --Alias-background-disabled-default: var(--Palette-gray-100);

  --Alias-background-neutral-subtle-default: var(--Palette-gray-100);

  --Alias-background-app-frame-layer-1: var(--Palette-gray-50);
  --Alias-background-app-frame-layer-2: var(--Palette-gray-25);
  --Alias-background-app-frame-elevated: var(--Palette-gray-25);

  --Alias-background-semantic-accent-default: var(--Palette-blue-900);
  --Alias-background-semantic-accent-hover: var(--Palette-blue-1000);

  --Alias-background-semantic-positive-default: #05834e;
  --Alias-background-semantic-positive-subtle-default: #d7f7e1;

  --Alias-background-semantic-notice-subtle-default: #ffeccf;
  --Alias-background-semantic-negative-default: #d73220;

  --Alias-drop-shadow-emphasized-default: var(--Palette-transparent-black-200);

  --Alias-content-neutral-subdued-default: var(--Palette-gray-700);
  --Alias-content-neutral-subdued-hover: var(--Palette-gray-800);

  --Alias-content-typography-heading: var(--Palette-gray-900);

  --Alias-content-typography-body: var(--Palette-gray-800);

  --Alias-content-neutral-default: var(--Palette-gray-800);
  --Alias-content-neutral-hover: var(--Palette-gray-900);
  --Alias-content-neutral-down: var(--Palette-gray-900);
  --Alias-content-neutral-focus-hover: var(--Palette-gray-900);
  --Alias-content-neutral-focus: var(--Palette-gray-900);
  --Alias-content-neutral-key-focus: var(--Palette-gray-900);
  --Alias-content-disabled-default: var(--Palette-gray-400);

  --Alias-border-disabled-default: var(--Palette-gray-300);

  --Alias-focus-indicator-default: var(--Palette-blue-800);

  --Alias-track-default: var(--Palette-gray-300);

  --Alias-content-semantic-accent-selected: var(--Palette-blue-1000);

  --spectrum-neutral-subdued-content-color-default: var(
    --Alias-content-neutral-subdued-default
  );
  --spectrum-neutral-content-color-default: var(
    --Alias-content-neutral-default
  );

  --spectrum-heading-color: var(--Alias-content-typography-heading);

  --Alias-content-divider: var(--Palette-gray-100);
  --mod-divider-background-color: var(--Palette-gray-100);
}
