.name-tag {
  position: absolute;
  top: var(--spectrum-global-dimension-size-85);
  left: var(--spectrum-global-dimension-size-85);
  color: #fff;
  padding: var(--spectrum-global-dimension-size-65)
    var(--spectrum-global-dimension-size-125);
}

.controls {
  position: absolute;
  bottom: var(--spectrum-global-dimension-size-85);
  left: var(--spectrum-global-dimension-size-85);
  display: flex;
  width: 100%;
  padding-right: 15px;
  align-items: center;
  justify-content: space-between;
}

.controls-icon-container {
  overflow: hidden;
  height: 26px;
  border-radius: var(--spectrum-global-dimension-size-200);
  display: flex;
  align-items: center;
}

.action-btn {
  height: 26px;
  width: 34px;
  padding-top: var(--spectrum-global-dimension-size-50);
  align-items: center;
  display: flex;
  justify-content: center;
  background: transparent;
  border-radius: var(--spectrum-global-dimension-size-0);

  &:hover {
    background-color: rgb(63, 63, 63);
  }
}

.icon-toggle-off {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: var(--spectrum-global-dimension-size-85);
    left: -var(--spectrum-global-dimension-size-50);
    height: var(--spectrum-global-dimension-size-10);
    width: 21px;
    background: #fff;
    transform: rotate(44deg);
  }
}
