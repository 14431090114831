@import '../variables.scss';

.container {
  --container-background-color: var(--Palette-gray-100);

  // UEC Overrides
  --spectrum-alias-appframe-border-color: var(--Palette-gray-100);

  position: relative;
  display: grid;
  grid-template-columns: var(--studio-rail-width) auto 1fr auto var(
      --studio-rail-width
    );
  grid-template-rows: min-content var(--studio-app-bar-height) auto;
  grid-template-areas:
    'notification-bar notification-bar notification-bar notification-bar notification-bar'
    'app-bar app-bar app-bar app-bar app-bar'
    'left-rail add-object-rail main panels right-rail';
  height: 100vh;
  width: 100vw;
  gap: 2px;
  background: var(--container-background-color);
  overflow: hidden;
  transition: grid-template-columns 200ms;

  &[data-size-preference='l'] {
    --studio-firefly-contextual-menu-height: 137px;
  }

  &[data-size-preference='m'] {
    --studio-firefly-contextual-menu-height: 128px;
  }

  &[data-size-preference='s'] {
    --studio-firefly-contextual-menu-height: 128px;
  }
}

.minimal-layout {
  grid-template-columns: 0px 0px 1fr 0px 0px;
}

.notification-bar {
  overflow: hidden;
  position: relative;
  grid-area: notification-bar;
  height: auto;
  width: 100%;
}

.app-bar {
  grid-area: app-bar;
}

.left-rail {
  overflow: hidden;
  grid-area: left-rail;
}

.add-object-rail {
  overflow: hidden;
  grid-area: add-object-rail;
}

.main {
  grid-area: main;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.canvas-container {
  position: relative;
  overflow: hidden;
}

.panels {
  grid-area: panels;
  position: relative;
  overflow: hidden;
}

.panels-container {
  background-color: var(--Palette-gray-50);
  position: relative;
  width: 320px;
  height: 100%;
}

.right-rail {
  grid-area: right-rail;
}

.main-firefly-preview {
  display: flex;
  gap: 12px;
  padding: 0 29px 0 29px;
}

.main-firefly-preview-section {
  height: calc(
    100% - var(--studio-firefly-contextual-menu-height) -
      var(--spectrum-global-dimension-size-250, 20px) - 14px -
      var(--studio-firefly-generated-image-height)
  );
  padding-top: 34px;
  padding-bottom: 18px;
  width: calc(50% - 12px / 2);
  position: relative;
  display: flex;
  align-items: center;
}

.max-size {
  height: 100%;
  width: 100%;
  position: relative;
}

.border-radius {
  border-radius: var(--spectrum-global-dimension-size-200, 8px);
}

.progress-circle-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--Palette-gray-25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.floating-top-left-action-buttons {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 9999;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.styles-environment-action-menus {
  height: var(--mod-styles-environment-action-menus-height, 32px);
  padding: 0 4px;
  background: var(--Alias-background-app-frame-layer-2);
  box-shadow: 0px 2px 8px 0px var(--Palette-transparent-black-300);
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

sp-theme.size-s {
  --mod-panel-accordion-label-button-top: -3px;

  ue-color-picker {
    --ue-color-picker-overlay-trigger-height: 24px;
  }
}

sp-theme.size-m {
  --mod-fieldlabel-line-height: normal;
  --mod-fieldlabel-font-size: 13px;
  --mod-slider-font-size: 13px;
  --mod-textfield-spacing-block-end: 5px;

  --panel-accordion-gap: 9px;
  --panel-gap: 13px;
  --mod-panel-accordion-label-button-top: -8px;
  --mod-object-properties-repeat-copy-number-field-width: 64px;

  ue-slider {
    --mod-stepper-width: 62px;
  }

  .panels-container {
    width: 330px;
  }
}

sp-theme.size-l {
  --mod-ue-scene-navigation-control-height: 32px;
  --mod-ue-scene-navigation-height: 400px;
  --ue-sortable-list-stack-width: 74px;
  --ue-scene-navigation-card-height: 50px;
  --ue-scene-navigation-card-width: 50px;
  --mod-ue-sortable-list-layer-card-width: 50px;
  --mod-ue-sortable-list-layer-card-height: 50px;
  -mod-ue-scene-navigation-card-width: 50px;
  -mod-ue-scene-navigation-card-height: 50px;
  --mod-ue-scene-navigation-card-thumbnail-width: 30px;
  --mod-ue-scene-navigation-card-thumbnail-height: 30px;

  --mod-fieldlabel-line-height: normal;
  --mod-fieldlabel-font-size: 15px;
  --mod-slider-font-size: 15px;
  --mod-textfield-spacing-block-end: 5px;
  --mod-menu-section-header-font-size: 16px;
  --mod-dialog-confirm-title-text-size: 20px;
  --mod-dialog-confirm-description-text-size: 16px;

  --mod-card-description-font-size: 16px;

  --mod-chat-panel-font-size: 16px;
  --mod-chat-panel-timestamp-font-size: 14px;

  --mod-firefly-dialog-body-font-size: 18px;
  --mod-firefly-preview-empty-description-font-size: 16px;
  --mod-firefly-context-bar-input-label-font-size: 16px;
  --mod-firefly-panel-thumbnail-container-size: 105px;
  --mod-firefly-panel-reference-effects-grid-template-columns: 1fr 1fr 1fr 1fr;
  --mod-firefly-panel-reference-effects-gap: 1px;
  --mod-firefly-tos-dialog-width: 530px;
  --mod-firefly-tos-img-height: 155px;
  --mod-firefly-tos-img-object-fit: cover;
  --mod-firefly-tos-dialog-header-font-size: 24px;
  --mod-firefly-onboarding-dialog-width: 860px;
  --mod-firefly-onboarding-dialog-height: 550px;
  --mod-firefly-onboarding-dialog-confirm-btn-width: 170px;

  --mod-orbit-controls-size: 40px;

  --mod-object-properties-text-number-field-width: 110px;
  --mod-object-properties-repeat-copy-number-field-width: 80px;

  --panel-accordion-gap: 9px;
  --panel-gap: 13px;
  --mod-panel-accordion-label-button-top: -10px;
  --mod-panel-section-header-font-size: 16px;

  --mod-object-properties-layer-info-font-size: 16px;

  --mod-sortable-element-container-height: 40px;
  --mod-sortable-element-icon-container-size: 30px;
  --mod-sortable-element-icon-size: 20px;
  --mod-sortable-element-icon-name-font-size: 16px;
  --mod-sortable-element-tag-font-size: 14px;

  --studio-rail-width: 56px;
  --mod-studio-app-bar-body-font-size: 16px;
  --mod-studio-app-bar-logo-size: 40px;
  --mod-studio-app-bar-user-profile-thumbnail-size: 32px;

  --mod-styles-environment-action-menus-height: 40px;

  ue-slider {
    --mod-stepper-width: 70px;
  }

  .panels-container {
    width: 385px;
  }
}
