.animated-text-input__input {
  background-color: #71c766;
  color: #fff;
  border-radius: var(--spectrum-global-dimension-size-150)
    var(--spectrum-global-dimension-size-150)
    var(--spectrum-global-dimension-size-25)
    var(--spectrum-global-dimension-size-150);
  padding: var(--spectrum-global-dimension-size-125)
    var(--spectrum-global-dimension-size-0);
  transition: width 0.1s ease-out;
  outline: none;
  border: none;
  position: relative;
  right: var(--spectrum-global-dimension-size-0);
  text-align: center;

  &--read-only {
    top: 35px;
  }
}
