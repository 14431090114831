.container {
  --panel-section-header-pointer: pointer;
  --panel-card-padding: 12px 0 0 0;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.label {
  padding: 0 15px 0 12px;

  h6 {
    flex: 1;
  }
}

.icon {
  color: var(--Palette-gray-800);
  width: var(--accordion-chevron-size, 16px);

  svg {
    transform-origin: center;
    transform: rotate(90deg);
    transition: all 200ms;
  }
}

.icon-collapsed svg {
  transform: rotate(0deg);
}

.content-wrapper {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  gap: var(--panel-accordion-gap, 8px);
  transition: grid-template-rows 200ms;

  .content {
    padding-top: var(--panel-accordion-gap, 8px);
  }
}

.collapsed-content-wrapper {
  grid-template-rows: 0fr;
  padding-top: 0;

  .content {
    padding-top: 0;
  }
}

.content {
  overflow: hidden;
}

.content > div {
  display: flex;
  flex-direction: column;
  gap: var(--panel-accordion-gap, 8px);
  padding: 0 12px 12px 12px;
}

.container-collapsed {
  --panel-card-padding: 12px 0 12px 0;

  .content > div {
    padding: 0 12px 0 12px;
  }
}
