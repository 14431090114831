.container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  cursor: var(--panel-section-header-pointer, auto);

  h6 {
    display: flex;
    color: var(--Palette-gray-800, --spectrum-heading-color, #000);
    font-weight: var(--spectrum-heading-sans-serif-font-weight);
    line-height: var(--spectrum-heading-line-height);
    margin-block: unset;
    margin-inline: unset;
    font-size: var(
      --mod-panel-section-header-font-size,
      --spectrum-heading-size-xxs
    );
  }
}
