.container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--studio-app-bar-height) 32px;
  background-color: var(--Palette-gray-25);
}

.image {
  margin-top: 40px;
  width: 100%;
  object-fit: cover;
}

.heading {
  color: var(--Palette-gray-900);
  text-align: center;
  font-size: 16px;
  margin: 0;
  padding: 0;
  line-height: 20.8px;
  font-weight: 700;
  margin-top: 18px;
}

.body-text {
  color: var(--Palette-gray-800);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  text-align: center;
  margin-top: 12px;
}

.button {
  width: fit-content;
  align-self: center;
  margin-top: 23px;
}
