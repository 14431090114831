.dialog-base {
  --content-width: 1280px;
  --content-max-width: 90vw;
  --content-height: 800px;
  --content-max-height: 90vw;
  --border-radius: 16px;

  z-index: 9999;
}

.dialog {
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
  z-index: 0;
  height: var(--content-height);
  max-height: var(--content-max-height);
  width: var(--content-width);
  max-width: var(--content-max-width);
}

.dialog iframe {
  height: 100%;
  width: 100%;
  border: none;
}

.progress-circle-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--Palette-gray-25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  border-radius: var(--border-radius);
}
