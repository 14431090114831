.container {
  box-sizing: border-box;
  position: relative;
  height: var(--mod-sortable-element-container-height, 32px);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4px 8px 4px 2px;
  border-radius: 5px;
  cursor: grab;
  color: var(--Palette-gray-800);
}

.container:not(.no-hover-style):hover {
  color: var(--Palette-gray-900);
  background-color: var(--Palette-gray-100);
}

.selected {
  background: var(--Palette-blue-300);
}

.clear-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: unset;
  border: unset;
  padding: unset;
  cursor: pointer;
}

.left-action-container {
  position: relative;
  height: 22px;
  width: 22px;
}

.inner-container {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex: 1;
  gap: 8px;
  align-items: center;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

.border-top::after {
  content: '';
  position: absolute;
  border-top: 2px solid var(--Palette-gray-100);
  top: 0;
  left: 0;
  height: 1px;
  width: 100%;
  pointer-events: none;
}

.border-bottom::after {
  content: '';
  position: absolute;
  border-bottom: 2px solid var(--Palette-gray-100);
  top: 0;
  left: 0;
  height: 1px;
  width: 100%;
  pointer-events: none;
}

.element-icon-and-name {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 2px;
}

.right-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.show-group-elements-btn {
  height: 100%;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 12px;
  }
}

.element-icon-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--mod-sortable-element-icon-container-size, 24px);
  width: var(--mod-sortable-element-icon-container-size, 24px);

  svg {
    height: var(--mod-sortable-element-icon-size, 16px);
    width: var(--mod-sortable-element-icon-size, 16px);
  }
}

.name-input {
  margin-left: 4px;
  z-index: 9;
  height: calc(var(--mod-sortable-element-container-height, 32px) - 2px);
  width: 200px;
  --mod-textfield-focus-indicator-width: unset;
  --highcontrast-textfield-focus-indicator-color: transparent;
  --mod-textfield-focus-indicator-gap: unset;
}

.name {
  font-size: var(--mod-sortable-element-icon-name-font-size, 14px);
  margin: unset;
  padding: unset;
}

.combine-tag {
  box-sizing: border-box;
  display: inline-block;
  padding: 2px 6px;
  font-size: var(--mod-sortable-element-tag-font-size, 12px);
  line-height: normal;
  border: 1px solid rgb(70, 70, 70);
  border-radius: 4px;
}

.visibility-toggle {
  height: calc(var(--mod-sortable-element-icon-container-size, 24px) - 4px);
  width: calc(var(--mod-sortable-element-icon-container-size, 24px) - 4px);

  svg {
    transform: scale(0.8);
  }
}
