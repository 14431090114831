.container {
  --mod-link-text-color-secondary-default: #fff;
  --mod-link-text-color-secondary-hover: #fff;

  position: relative;
  height: 56px;
  width: 100%;
  background-color: var(--Alias-background-semantic-negative-default);
  display: flex;
  gap: 8px;
  align-items: center;
  padding-left: 12px;
  color: #fff;
  font-size: 14px;
  line-height: 17.53px;

  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 17.53px;
  }
}
