.container {
  width: 70px;
  height: 70px;
  height: 100%;
  width: 100%;
  flex-shrink: 0;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}
