.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 26px;
}

.views-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
}
