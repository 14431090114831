.share-project-dialog {
  position: relative;
  width: 510px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 16px;
  background: var(--Alias-background-app-frame-layer-2);
  padding: 32px;

  h2,
  p {
    padding: unset;
    margin: unset;
  }

  h2 {
    color: var(
      --Alias-content-neutral-default,
      var(--Alias-content-neutral-default, #292929)
    );
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 16px;
  }

  p {
    color: var(
      --Alias-content-typography-body,
      var(--Alias-content-typography-body, #292929)
    );

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 8px;
  }
}

.footer {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > sp-button-group {
    flex-wrap: nowrap;
  }
}
