.container {
  width: 100%;
  display: grid;
  grid-template-columns: 71.764705882% auto;
  justify-content: space-between;
  column-gap: 16px;
}

.slider {
  width: 100%;
}

.number-field {
  align-self: end;
  width: 100%;
}