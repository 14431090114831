.emoji-picker {
  width: 128px;
  background: #fff;
  border-radius: var(--spectrum-global-dimension-size-100);
  font-size: 1.9rem;

  :hover {
    cursor: pointer;
  }

  &__animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3em;
    animation: emoji-animation 5s ease-in-out forwards;
    z-index: 9999;
  }
}

@keyframes emoji-animation {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
  10% {
    transform: scale(1.4) translateY(-150px);
    opacity: 1;
  }
  20% {
    transform: scale(1.2) translateY(-100px);
    opacity: 1;
  }
  30% {
    transform: scale(1.4) translateY(-100px);
    opacity: 1;
  }
  40% {
    transform: scale(1.3) translateY(-100px);
    opacity: 1;
  }
  50% {
    transform: scale(1.4) translateY(-100px);
    opacity: 1;
  }
  100% {
    transform: scale(1.5) translateY(-100px);
    opacity: 0;
  }
}
