.container {
  z-index: 9999;

  h2 {
    padding: 0;
  }
}

.content {
  overflow-y: auto;
  background-color: white;
  border-radius: 24px;
  max-height: 92vh;
}

.radio-btn {
  margin-bottom: 4px;
  padding: 0px;
  width: fit-content;
  &:hover {
    cursor: pointer;
  }
}

.toast {
  position: fixed;
  bottom: var(--toast-bottom-position);
  left: 50%;
  transform: translateX(-50%);
}

.community_guidelines {
  text-decoration: underline;
}

.textfield {
  width: 100%;
}

.error {
  margin: 0;
  color: red;
}