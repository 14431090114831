.dialog {
  width: 300px;

  --spectrum-dialog-confirm-padding: var(
    --spectrum-global-dimension-size-250,
    20px
  );
}

.progress-bar-container {
  position: relative;
  width: 100%;
  padding-top: var(--spectrum-global-dimension-size-325, 26px);
}
