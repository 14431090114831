$app-bar--Height: 60px;
$discover--app-bar--Height: 80px;
$properties-navbar--Height: var(--spectrum-global-dimension-size-600, 48px);
$rail--Width: 64px;

$medium--Action-Button--Size: 32px;
$medium--icon-m--Size: 20px;

$popover--Width: 275px;
$views-card--Height: 208px;
$views-card--Width: 272px;

// Breakpoints
$breakpoint-extra-small: 375px;
$breakpoint-small: 597px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-extra-large: 1200px;
