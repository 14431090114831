.dialog {
  position: relative;
  width: var(--mod-firefly-tos-dialog-width, 480px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 16px;
  background: var(--Alias-background-app-frame-layer-2);

  img {
    width: 100%;
    height: var(--mod-firefly-tos-img-height, 140px);
    object-fit: var(--mod-firefly-tos-img-object-fit, contain);
  }

  h2,
  p {
    margin: unset;
    padding: 0 32px;
  }

  h2 {
    color: var(--Alias-content-typography-heading);
    font-size: var(--mod-firefly-tos-dialog-header-font-size, 22px);
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 28.6px */
    padding-top: 32px;
  }

  p,
  a {
    color: var(--Alias-content-typography-body);
    font-size: var(--mod-firefly-dialog-body-font-size, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }

  p {
    padding-top: 18px;
  }

  p:nth-of-type(2) {
    padding-top: 24px;
  }

  a {
    color: var(--Alias-background-semantic-accent-default);
    text-decoration-line: underline;
  }

  sp-button-group {
    margin: 32px;
    align-self: flex-end;

    sp-button[treatment='outline'][variant='secondary'] {
      background-color: var(--Alias-background-app-frame-layer-2);
    }
  }
}

.no-pointer-events * {
  pointer-events: none;
}
