.container {
  height: 100%;
  overflow: auto;
  padding: 0 16px;
}

.header {
  margin: 16px 0;
}

.flex-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}

.community-card {
  height: 160px;
  width: 100%;
  border-radius: 8px;
}

.community-card-image {
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
}
