.popover {
  position: relative;
  display: flex;
  width: var(--popover-width, 268px);
  flex-direction: column;
  gap: 6px;
  padding: 12px 12px 16px 12px;
  border-radius: 8px;
  background: var(--Alias-background-app-frame-layer-1, #f8f8f8);
  box-shadow: 0px 2px 8px 0px var(--Palette-transparent-black-300);

  h2 {
    padding: unset;
    margin: unset;
    margin-bottom: 11px;
    color: var(
      --Alias-content-typography-heading,
      var(--Alias-content-typography-heading, #131313)
    );
    font-size: var(--popover-heading-font-size, 14.81px);
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }
}

.popover[data-input-size='s'] {
  ue-color-picker {
    --ue-color-picker-overlay-trigger-height: 24px;
  }
}

.popover[data-input-size='m'] {
  --popover-width: 298px;
  --popover-heading-font-size: 16px;
}
