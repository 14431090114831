.chat-panel {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: var(--spectrum-global-dimension-size-0)
    var(--spectrum-global-dimension-size-200);

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
  }

  &__heading {
    margin: var(--spectrum-global-dimension-size-200)
      var(--spectrum-global-dimension-size-100);
  }

  &__chat-container {
    flex: 1;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  &__message-container {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: var(--spectrum-global-dimension-size-200) 0;
  }

  &__message-list {
    flex-direction: column;
  }

  &__input-container {
    flex-shrink: 0;
    height: auto;
    margin-top: var(--spectrum-global-dimension-size-150);
  }
}

.chat {
  width: 100%;
  border-radius: var(--spectrum-alias-border-radius-medium);

  &__message-container {
    display: flex;
    flex-direction: column;
    gap: var(--spectrum-global-dimension-size-50);

    p {
      margin: var(--spectrum-global-dimension-size-0);
      overflow-wrap: break-word;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spectrum-global-dimension-size-100);
    color: var(--Alias-content-typography-body);
    font-size: var(--mod-chat-panel-font-size, 14px);
  }

  &__message {
    padding: var(--spectrum-global-dimension-size-100)
      var(--spectrum-global-dimension-size-125);
    border-radius: var(--spectrum-alias-border-radius-medium);
    font-size: var(--mod-chat-panel-font-size, 14px);

    &--local-user {
      background-color: #1473e6;
      color: var(--Alias-content-typography-body);
    }

    &--remote-user {
      background-color: #f8f8f8;
      color: #5b5b5b;
    }
  }

  &__timestamp {
    text-align: end;
    margin-top: -var(--spectrum-global-dimension-size-25);
    padding-right: var(--spectrum-global-dimension-size-50);
    color: #9e9d9d;
    font-size: var(--mod-chat-panel-timestamp-font-size, 12px);
  }
}

.chat-input {
  display: flex;
  flex-direction: row;
  gap: var(--spectrum-global-dimension-size-75);

  &__textfield {
    width: 100%;
  }

  &__emoji-icon {
    > button {
      > svg {
        fill: #1473e6 !important;
      }
    }
    transform: translateX(5px) translateY(2px);
  }
}
