.container {
  padding-left: var(--spectrum-global-dimension-size-0)
    var(--spectrum-global-dimension-size-50);
}

.header {
  margin-top: var(--spectrum-global-dimension-size-0);
  margin-bottom: 20px;
  margin-bottom: var(--spectrum-global-dimension-size-250);
}

.avatar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spectrum-global-dimension-size-125);
  position: relative;

  &__avatar {
    display: flex;
    flex: 1;
    align-items: center;
    gap: var(--spectrum-global-dimension-size-125);
    width: var(--spectrum-global-dimension-size-10);
  }

  &__avatar-name {
    margin: var(--spectrum-global-dimension-size-0);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
