.heading {
  padding: 0;
  margin: 0 0 16px 0;
}

.legal-item {
  color: var(--Palette-blue-900);
  margin: 0;
  padding: 0;

  &--plain-text {
    color: var(--Palette-gray-800);
  }
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}
