.dialog {
  width: 640px;
  height: 464px;
  // position: relative;
  overflow: hidden;
  border-radius: 12px;
  background-color: var(--Alias-background-app-frame-elevated);
  display: grid;
  grid-template-rows: 140px 324px;
}

.dialog img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    color: var(--Alias-content-typography-heading);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin: unset;
  }
}

.text-container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  p {
    color: var(--Alias-content-typography-body);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: unset;
  }
}

.button {
  align-self: flex-end;
}