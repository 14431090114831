.container {
  width: 96px;
  height: 48px;
  padding: 8px;
  border-radius: 8px;
  background-color: var(--spectrum-background-layer-2-color);
  z-index: 9996;
  position: absolute;
  right: 24px;
  display: flex;
  justify-content: space-between;

  &--firefly-popover-opened {
    bottom: calc(
      var(--spectrum-global-dimension-size-250, 20px) +
        var(--studio-firefly-contextual-menu-height) + 14px
    );
  }

  &--firefly-popover-closed {
    bottom: 14px;
  }
}

.divider {
  width: 2px;
  height: 24px;
  align-self: center;
  border-radius: 999;
  background-color: #3f3f3f;
}
