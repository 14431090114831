sp-theme[color='dark'] {
  /*
    Colors
  */

  --Palette-gray-25: #111;
  --Palette-gray-50: #1b1b1b;
  --Palette-gray-75: #222;
  --Palette-gray-100: #2c2c2c;
  --Palette-gray-200: #323232;
  --Palette-gray-300: #393939;
  --Palette-gray-400: #444;
  --Palette-gray-500: #6d6d6d;
  --Palette-gray-600: #8a8a8a;
  --Palette-gray-700: #afafaf;
  --Palette-gray-800: #dbdbdb;
  --Palette-gray-900: #f2f2f2;
  --Palette-gray-1000: #fff;

  --Palette-blue-300: #0c2175;
  --Palette-blue-700: #345bf8;
  --Palette-blue-800: #4069fd;
  --Palette-blue-900: #5681ff;
  --Palette-blue-1000: #6995fe;

  --Palette-transparent-black-200: rgba(0, 0, 0, 0.12);
  --Palette-transparent-black-300: rgba(0, 0, 0, 0.15);

  --Alias-background-neutral-selected-default: var(--Palette-gray-800);
  --Alias-background-neutral-selected-hover: var(--Palette-gray-900);
  --Alias-background-disabled-default: var(--Palette-gray-100);

  --Alias-background-neutral-subtle-default: var(--Palette-gray-100);

  --Alias-background-app-frame-layer-1: var(--Palette-gray-50);
  --Alias-background-app-frame-layer-2: var(--Palette-gray-75);
  --Alias-background-app-frame-elevated: var(--Palette-gray-75);

  --Alias-background-semantic-accent-default: var(--Palette-blue-700);
  --Alias-background-semantic-accent-hover: var(--Palette-blue-1000);

  --Alias-background-semantic-positive-default: #068850;
  --Alias-background-semantic-positive-subtle-default: #003326;

  --Alias-background-semantic-notice-subtle-default: #501b00;
  --Alias-background-semantic-negative-default: #df3422;

  --Alias-drop-shadow-emphasized-default: var(--Palette-transparent-black-200);

  --Alias-content-neutral-subdued-default: var(--Palette-gray-700);
  --Alias-content-neutral-subdued-hover: var(--Palette-gray-800);

  --Alias-content-typography-heading: var(--Palette-gray-900);

  --Alias-content-typography-body: var(--Palette-gray-800);

  --Alias-content-neutral-default: var(--Palette-gray-800);
  --Alias-content-neutral-hover: var(--Palette-gray-900);
  --Alias-content-neutral-down: var(--Palette-gray-900);
  --Alias-content-neutral-focus-hover: var(--Palette-gray-900);
  --Alias-content-neutral-focus: var(--Palette-gray-900);
  --Alias-content-neutral-key-focus: var(--Palette-gray-900);
  --Alias-content-disabled-default: var(--Palette-gray-400);

  --Alias-border-disabled-default: var(--Palette-gray-300);

  --Alias-focus-indicator-default: var(--Palette-blue-800);

  --Alias-track-default: var(--Palette-gray-300);

  --Alias-content-semantic-accent-selected: var(--Palette-blue-1000);

  --spectrum-neutral-subdued-content-color-default: var(
    --Alias-content-neutral-subdued-default
  );
  --spectrum-neutral-content-color-default: var(
    --Alias-content-neutral-default
  );

  --spectrum-heading-color: var(--Alias-content-typography-heading);

  --Alias-content-divider: var(--Palette-gray-200);
  --mod-divider-background-color: var(--Palette-gray-200);
}
