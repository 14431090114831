.tooltip {
  --mod-tooltip-max-inline-size: auto;

  sp-tooltip-openable {
    margin-left: 200px;
  }
}

.tooltip-content {
  display: flex;
  align-items: center;
  gap: var(--spectrum-global-dimension-size-75, 6px);

  svg {
    width: 17px;
    margin-left: -5px;
  }

  svg path {
    fill: currentColor;
  }
}

.space {
  border-color: currentColor;
  border-radius: var(
    --spectrum-popover-border-radius,
    var(--spectrum-alias-border-radius-regular)
  );
  border-style: solid;
  border-width: var(
    --spectrum-popover-border-size,
    var(--spectrum-alias-border-size-medium)
  );
  padding: var(--spectrum-global-dimension-size-0, 0)
    var(--spectrum-global-dimension-size-50, 4px);
}

.divider {
  margin: var(--spectrum-global-dimension-size-100, 8px) 0;
}
