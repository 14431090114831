.text {
  position: absolute;
  z-index: 9;
  font-size: var(--spectrum-font-size-200, 16px);
  line-height: 16px;
  font-weight: var(--spectrum-global-font-weight-regular, 400);
  color: #f3f3f3;
  text-shadow: 0px 0px 1px rgba(0, 0, 0);
  margin: unset;
  padding: unset;
}
