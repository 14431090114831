.container {
  --mod-textfield-width: 99px;

  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 0;

  sp-field-label {
    flex: 1;
  }
}
