.popover {
  position: absolute;
  transform: translateY(calc(-100% - 22px));
  z-index: 9;
  top: 0;
  right: 0;
  width: 296px;
  min-width: 296px;
  height: auto;
  min-height: fit-content;
  padding: 24px;
  border-radius: 10px;
  background-color: var(--Palette-gray-75);
  display: flex;
  flex-direction: column;
  gap: 12px;

  h6,
  p {
    margin: 0;
    padding: 0;
  }

  h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20.8px;
    color: var(--Alias-content-typography-heading);
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--Alias-content-typography-body);
  }

  sp-button {
    width: fit-content;
    align-self: flex-end;
  }
}
