.img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -65%);
  border-radius: 50%;
  height: 40%;
  width: 40%;
  object-fit: contain;
}
