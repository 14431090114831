.action-btn {
  color: white;
}

.popover {
  position: relative;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: transparent;
  border: 0;
  padding: 12px 12px;
  border-radius: 8px;
  user-select: none;

  &:hover {
    cursor: pointer;
    background: var(--Palette-gray-100);
  }
}
