.container {
  height: 100%;
  overflow: auto;
  padding: var(--spectrum-global-dimension-size-0)
    var(--spectrum-global-dimension-size-200);
}

.header {
  margin: var(--spectrum-global-dimension-size-200)
    var(--spectrum-global-dimension-size-0);
}

.flex-container {
  display: flex;
  flex-direction: column;
  gap: var(--spectrum-global-dimension-size-200);
  margin-bottom: var(--spectrum-global-dimension-size-200);
}

.textfield {
  width: 100%;
}
