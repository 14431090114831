.container {
  --swc-menu-width: 181px;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  z-index: 100;

  ue-scene-navigation {
    box-shadow: 0px 1px 6px 0px var(--Alias-drop-shadow-emphasized-default);
  }
}
