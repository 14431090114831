@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--spectrum-global-color-gray-50);
}

.header {
  position: fixed;
  top: var(--spectrum-global-dimension-size-0);
  width: 100%;
  z-index: 9999;
}

.main {
  flex-grow: 1;
  padding-top: $discover--app-bar--Height;
  padding-bottom: var(--spectrum-global-dimension-size-0, 0);
  width: 100%;
  background-color: var(--spectrum-global-color-gray-50);
}
