.dialog {
  position: relative;
  height: 420px;
  width: 640px;
  border-radius: 10px;
  background: var(--Alias-background-app-frame-elevated);
  padding: 8px 12px;
  display: grid;
  grid-template-columns: var(--side-navigation-item-width) 1fr;
  grid-template-rows: 32px 1fr;
  grid-template-areas:
    'header header'
    'side-nav main';
  gap: 8px;
}

.close-btn {
  --mod-actionbutton-icon-size: 20px;
  --mod-actionbutton-background-color-default: var(
    --Alias-background-app-frame-elevated
  );
  --mod-actionbutton-content-color-default: var(
    --Alias-content-neutral-default
  );

  height: var(--close-button-size, 32px);
  width: var(--close-button-size, 32px);
  border-radius: var(--close-button-border-radius, 50%);
  background-color: var(--Alias-background-app-frame-elevated);
  border-color: transparent;
  position: absolute;
  top: 8px;
  right: 12px;
}

.header {
  grid-area: header;
  padding-left: 4px;
  display: flex;
  align-items: center;

  h2 {
    padding: unset;
    margin: unset;
    color: var(--Alias-content-typography-heading);
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
  }
}

.side-navigation {
  grid-area: side-nav;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.main-content {
  grid-area: main;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 16px;
  background-color: var(--Alias-background-app-frame-layer-1);
  padding: 8px;

  sp-field-label[size='m'] {
    --mod-fieldlabel-font-size: 14px;
  }
}

.description {
  padding: unset;
  margin: unset;
  color: var(--Alias-content-typography-body);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-wrap: pretty;
}
