.container {
  box-sizing: border-box;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--panel-gap, 12px);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--Alias-background-app-frame-layer-1, #f8f8f8);
}
