.container {
  position: fixed;
  background-color: var(--Palette-gray-25);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &--fade-in {
    animation: fade-in 1s linear forwards;
  }

  &--fade-out {
    animation: fade-out 0.5s linear forwards;
  }
}

.image {
  width: 322px;
  object-fit: cover;
}

.text {
  padding: 0;
  color: var(--Palette-gray-800);
  text-align: center;
}

.heading {
  width: 690px;
  font-weight: 800;
  font-size: 45px;
  margin: 24px 0 0 0;
  line-height: 58.5px;
}

.body-text {
  margin: 18px 0 0 0;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  width: 510px;
}

// Some logic in ToNeoLoadingScreen.tsx depends on these two animation names (fade-in and fade-out). DO NOT change it without also updating ToNeoLoadingScreen.tsx
@keyframes fade-in {
  0% {
    opacity: 0;
    z-index: -1;
  }

  50% {
    opacity: 1;
    z-index: 99999999999;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    z-index: 99999999999;
  }

  100% {
    opacity: 0;
    z-index: -1;
  }
}
