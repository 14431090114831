@import 'styles/variables';

.header {
  margin: unset;
  padding: 15px 0;
}

.help-dialog-content {
  p {
    margin-block: unset;
    margin-inline: unset;
  }
}

.flex-container {
  display: flex;
  justify-content: start;
  align-items: center;
}

.flex-action,
.flex-shortcut {
  padding: 2px 8px;
}

.flex-action {
  font-weight: bold;
  width: 400px;
  background-color: var(--Palette-gray-50, #f8f8f8);
  text-wrap: nowrap;
  border-bottom: 1px solid var(--Palette-gray-300, #dadada);
}

.flex-shortcut {
  background-color: var(--Palette-gray-300, #dadada);
  min-width: 530px;
  text-wrap: nowrap;
}

@media (max-width: $breakpoint-medium) {
  .flex-action,
  .flex-shortcut {
    font-size: 12px;
    border-bottom: 1px solid var(--Palette-gray-300, #dadada);
  }

  .flex-action {
    width: 100%;
  }

  .flex-shortcut {
    text-align: right;
    min-width: unset;
    background-color: var(--Palette-gray-50, #f8f8f8);
  }
}

@media (max-width: $breakpoint-small) {
  .flex-action,
  .flex-shortcut {
    font-size: 10px;
    border-bottom: none;
  }

  .flex-container {
    flex-direction: column;
  }

  .flex-shortcut {
    display: flex;
    width: 100%;
    background-color: var(--Palette-gray-25, #fff);
  }
}
