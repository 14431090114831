@import './light.scss';
@import './dark.scss';

/*
    SWC
    - changes SWC spectrum 1 design to spectrum 2 design https://www.figma.com/design/Mngz9H7WZLbrCvGQf3GnsY/S2-%2F-Desktop?node-id=0-1&node-type=CANVAS&m=dev
  */

sp-theme {
  --Palette-white: #fff;

  --mod-disabled-content-color: var(--Alias-content-disabled-default);
  --spectrum-border-width-100: 2px;
  --spectrum-corner-radius-100: 7px;
  --spectrum-corner-radius-200: 8px;

  // ue-scene-navigation

  --mod-ue-scene-navigation-background: var(
    --Alias-background-app-frame-layer-1
  );
  --mod-ue-scene-navigation-control-background: var(
    --Alias-background-app-frame-layer-2
  );
  --mod-ue-scene-navigation-card-background: var(--Palette-gray-100);
  --mod-ue-scene-navigation-card-background-hovering: var(--Palette-gray-200);
  --mod-ue-scene-navigation-control-border-color: var(--Alias-content-divider);

  // sp-action-button

  --mod-actionbutton-border-width: 0px;
  --mod-actionbutton-edge-to-hold-icon: 4px;

  sp-action-button {
    font-weight: 500;
  }

  sp-action-button[size='s'] {
    --mod-actionbutton-border-radius: 7px;
  }

  sp-action-button[size='l'] {
    --mod-actionbutton-border-radius: 10px;
  }

  sp-action-button:not([quiet]):not([emphasized]) {
    --mod-actionbutton-border-color-default: var(--Palette-gray-100);
    --mod-actionbutton-border-color-hover: var(--Palette-gray-200);
    --mod-actionbutton-background-color-default: var(--Palette-gray-100);
    --mod-actionbutton-background-color-hover: var(--Palette-gray-200);
    --mod-actionbutton-content-color-default: var(
      --Alias-content-neutral-default
    );
    --mod-actionbutton-content-color-hover: var(--Alias-content-neutral-hover);
  }

  sp-action-button:not([emphasized])[selected] {
    --mod-actionbutton-border-color-default: var(
      --Alias-background-neutral-selected-default
    );
    --mod-actionbutton-border-color-hover: var(
      --Alias-background-neutral-selected-hover
    );
    --mod-actionbutton-background-color-default: var(
      --Alias-background-neutral-selected-default
    );
    --mod-actionbutton-background-color-hover: var(
      --Alias-background-neutral-selected-hover
    );
    --mod-actionbutton-content-color-default: var(--Palette-gray-25);
    --mod-actionbutton-content-color-hover: var(--Palette-gray-25);
  }

  sp-action-button[selected][emphasized] {
    --mod-actionbutton-border-color-default: var(
      --Alias-background-semantic-accent-default
    );
    --mod-actionbutton-border-color-hover: var(
      --Alias-background-semantic-accent-hover
    );
    --mod-actionbutton-background-color-default: var(
      --Alias-background-semantic-accent-default
    );
    --mod-actionbutton-background-color-hover: var(
      --Alias-background-semantic-accent-hover
    );
    --mod-actionbutton-background-color-down: var(
      --Alias-background-semantic-accent-hover
    );
    --mod-actionbutton-content-color-default: var(--Palette-white);
    --mod-actionbutton-content-color-hover: var(--Palette-white);
  }

  // sp-action-group

  sp-action-group[compact]:not([vertical]) {
    --mod-actiongroup-horizontal-spacing-compact: 2px;
  }

  // sp-action-menu

  ue-action-menu {
    --mod-actionbutton-background-color-default-selected: var(
      --Alias-background-neutral-selected-default
    );
    --mod-actionbutton-background-color-hover-selected: var(
      --Alias-background-neutral-selected-hover
    );
  }

  ue-action-menu[size='s'] {
    --mod-menu-item-border-radius: 7px;
  }

  ue-action-menu[size='l'] {
    --mod-menu-item-border-radius: 10px;
  }

  // sp-button

  sp-button[variant='accent'] {
    --mod-button-background-color-default: var(
      --Alias-background-semantic-accent-default
    );
    --mod-button-background-color-hover: var(
      --Alias-background-semantic-accent-hover
    );
  }

  sp-button[variant='secondary'] {
    --mod-button-background-color-default: var(--Palette-gray-100);
    --mod-button-background-color-hover: var(--Palette-gray-200);
  }

  sp-button[variant='secondary'][treatment='outline'] {
    --mod-button-background-color-default: transparent;
    --mod-button-background-color-hover: var(--Palette-gray-100);
  }

  sp-button[variant='secondary'][treatment='outline'][static='white'] {
    --mod-button-background-color-default: transparent;
    --mod-button-background-color-hover: var(--Palette-transparent-black-200);
  }

  // sp-dialog

  --mod-modal-background-color: var(--Alias-background-app-frame-elevated);
  --mod-dialog-confirm-title-text-color: var(
    --Alias-content-typography-heading
  );
  --mod-dialog-confirm-description-text-color: var(
    --Alias-content-typography-body
  );

  // sp-divider

  --mod-divider-background-color: var(--Alias-content-divider);

  // sp-field-label

  --mod-fieldlabel-line-height: normal;

  // sp-link

  --mod-link-text-color-secondary-default: var(--Alias-content-neutral-default);
  --mod-link-text-color-secondary-active: var(--Alias-content-neutral-down);
  --mod-link-text-color-secondary-hover: var(--Alias-content-neutral-hover);

  // sp-text-field

  --mod-textfield-background-color: var(--Palette-gray-25);
  --mod-textfield-text-color-default: var(--Alias-content-neutral-default);
  --mod-textfield-border-width: var(--spectrum-border-width-100);
  --mod-textfield-border-color: var(--Palette-gray-300);
  --mod-textfield-background-color: var(--Palette-gray-25);
  --mod-textfield-text-color-default: var(--Alias-content-neutral-default);
  --mod-textfield-text-color-hover: var(--Alias-content-neutral-hover);
  --mod-textfield-border-color-hover: var(--Palette-gray-400);
  --mod-textfield-border-color-keyboard-focus: var(--Palette-gray-800);
  --mod-textfield-border-color-focus-hover: var(--Palette-gray-900);
  --mod-textfield-text-color-disabled: var(--Alias-content-disabled-default);
  --mod-textfield-background-color-disabled: --Palette-gray-25;
  --mod-textfield-border-color-disabled: var(--Alias-border-disabled-default);
  --mod-textfield-focus-indicator-color: var(--Alias-focus-indicator-default);

  // sp-picker

  ue-picker[size='s'] {
    --mod-menu-item-border-radius: 7px;
  }

  ue-picker[size='l'] {
    --mod-menu-item-border-radius: 10px;
  }

  --mod-picker-background-color-default: var(--Palette-gray-100);
  --mod-picker-border-color-default: var(--Palette-gray-100);
  --mod-picker-font-color-hover: var(--Alias-content-neutral-hover);
  --mod-picker-background-color-hover: var(--Palette-gray-200);
  --mod-picker-border-color-hover: var(--Palette-gray-200);
  --mod-picker-font-color-default-open: var(--Alias-content-neutral-focus);
  --mod-picker-background-color-default-open: var(--Palette-gray-200);
  --mod-picker-border-default-open: var(--Palette-gray-200);
  --mod-picker-font-color-hover-open: var(--Alias-content-neutral-focus-hover);
  --mod-picker-background-color-hover-open: var(--Palette-gray-200);
  --mod-picker-border-color-hover-open: var(--Palette-gray-200);
  --mod-picker-background-color-key-focus: var(--Palette-gray-200);
  --mod-picker-border-color-key-focus: var(--Palette-gray-200);
  --mod-picker-font-color-key-focus: var(--Alias-content-neutral-key-focus);
  --mod-picker-focus-indicator-color: var(--Alias-focus-indicator-default);
  --mod-picker-font-color-disabled: var(--Alias-content-disabled-default);
  --mod-picker-background-color-disabled: var(
    --Alias-background-disabled-default
  );
  --mod-picker-spacing-edge-to-disclosure-icon: 5px;

  // sp-number-field

  --mod-textfield-corner-radius-hide-stepper: var(--spectrum-corner-radius-100);
  --mod-stepper-border-radius: var(--spectrum-corner-radius-100);
  --mod-stepper-border-width: var(--spectrum-border-width-100);
  --mod-stepper-border-color-hover: var(--Alias-border-disabled-default);
  --mod-stepper-border-color-focus-hover: var(
    --Alias-content-neutral-focus-hover
  );
  --mod-stepper-border-color-keyboard-focus: var(--Palette-gray-800);
  --mod-stepper-focus-indicator-color: var(--Alias-focus-indicator-default);

  --mod-stepper-border-color: var(--Palette-gray-300);
  --mod-stepper-buttons-border-color: var(--Palette-gray-300);
  --mod-ue-number-field-button-border-width: 2px;
  --mod-infield-button-border-radius: 7px;
  --mod-infield-button-background-color-hover: var(--Palette-gray-200);
  --mod-infield-button-background-color-disabled: var(
    --Alias-background-disabled-default
  );
  --mod-infield-button-icon-color-disabled: var(
    --Alias-content-disabled-default
  );
  --mod-infield-button-fill-padding: 2px;

  // sp-slider

  --mod-slider-label-top-to-text: 0px;
  --mod-slider-track-thickness: 4px;
  --mod-slider-handle-gap: 0px;
  --mod-slider-handle-size: 18px;
  --mod-slider-handle-border-radius: 50%;
  --mod-slider-track-fill-thickness: 4px;
  --mod-slider-track-handleoffset: 0px;
  --mod-slider-track-color: var(--Alias-track-default);
  --mod-slider-track-fill-color: var(--Palette-gray-700);
  --mod-slider-handle-border-color: var(--Palette-gray-800);
  --mod-slider-handle-background-color: var(--Palette-gray-25);
  --mod-slider-handle-border-color-key-focus: var(--Palette-gray-900);
  --mod-slider-handle-focus-ring-color-key-focus: var(
    --Alias-focus-indicator-default
  );
  --mod-slider-handle-border-color-down: var(--Palette-gray-800);
  --mod-slider-ramp-track-color-disabled: var(
    --Alias-background-disabled-default
  );
  --mod-slider-handle-background-color-disabled: var(
    --Alias-background-disabled-default
  );
  --mod-slider-handle-disabled-background-color: var(--Palette-gray-25);
  --mod-slider-handle-border-color-disabled: var(
    --Alias-border-disabled-default
  );
  --mod-slider-tick-mark-color: var(--Alias-track-default);

  ue-slider {
    --mod-stepper-width: 56px;
  }

  // sp-tabs

  --mod-tabs-color: var(--Alias-content-neutral-subdued-default);
  --mod-tabs-color-hover: var(--Alias-content-neutral-hover);
  --mod-tabs-color-selected: var(--Alias-content-neutral-default);
  --mod-tabs-selection-indicator-color: var(--Alias-content-neutral-default);
  --mod-tabs-divider-border-radius: 2px;
  --mod-tabs-font-weight: 500;

  // sp-menu

  --mod-menu-checkmark-icon-color-default: var(
    --Alias-background-semantic-accent-default
  );
  --mod-menu-checkmark-icon-color-hover: var(
    --Alias-background-semantic-accent-hover
  );
  --mod-menu-item-focused-ring-outline-color: var(
    --Alias-focus-indicator-default
  );

  ue-menu[size='s'] {
    --mod-menu-item-border-radius: 7px;
  }

  ue-menu[size='l'] {
    --mod-menu-item-border-radius: 10px;
  }

  // sp-menu-group

  --mod-menu-section-header-color: var(--Palette-gray-800);
  --mod-menu-section-header-font-size: 14px;
  --mod-menu-section-header-font-weight: 700;
  --mod-menu-section-header-line-height: normal;

  // ue-menu-item

  --mod-menu-item-border-radius: 9px;

  ue-menu-item {
    font-weight: 500;

    kbd[slot='value'] {
      color: var(--Alias-content-neutral-subdued-default);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  // sp-toast

  --mod-toast-corner-radius: 10px;
  --mod-toast-spacing-text-to-action-button-horizontal: 0;
  --mod-toast-divider-color: transparent;
  --mod-toast-positive-background-color-default: var(
    --Alias-background-semantic-positive-default
  );

  sp-toast[variant='info'] {
    --mod-toast-informative-background-color-default: var(--Palette-blue-900);
  }

  // sp-popover

  --mod-popover-content-area-spacing-vertical: 0;
  --mod-popover-corner-radius: 10px;
  --mod-popover-border-width: 0;
  --mod-popover-shadow-horizontal: 0px;
  --mod-popover-shadow-vertical: 0px;
  --mod-popover-shadow-blur: 4px;
  --mod-popover-shadow-spread: 0px;
  --mod-popover-shadow-color: var(
    --Alias-drop-shadow-elevated,
    rgba(0, 0, 0, 0.16)
  );
  --mod-popover-background-color: var(--Alias-background-app-frame-elevated);

  // sp-switch

  --mod-switch-background-color: var(--Palette-gray-25);
  --mod-switch-handle-selected-background-color: var(--Palette-gray-25);
  --mod-switch-focus-indicator-color: var(--Alias-focus-indicator-default);

  ue-switch:not([emphasized]) {
    --mod-switch-handle-background-color: var(--Alias-content-neutral-default);
    --mod-switch-control-border-color: var(--Alias-content-neutral-default);
    --mod-switch-handle-background-color-hover: var(
      --Alias-content-neutral-hover
    );
    --mod-switch-control-border-color-hover: var(--Alias-content-neutral-hover);
    --mod-switch-background-color-selected-hover: var(
      --Alias-content-neutral-hover
    );
    --mod-switch-background-color-selected-default: var(
      --Alias-content-neutral-default
    );
    --mod-switch-handle-background-color-selected-hover: var(--Palette-gray-25);
  }

  ue-switch[emphasized] {
    --mod-switch-handle-background-color-selected-hover: var(--Palette-gray-25);
    --mod-switch-handle-background-color-hover: var(
      --Alias-background-semantic-accent-hover
    );
    --mod-switch-control-border-color-hover: var(
      --Alias-background-semantic-accent-hover
    );
    --mod-switch-background-color-selected-hover: var(
      --Alias-background-semantic-accent-hover
    );
    --mod-switch-handle-background-color: var(
      --Alias-background-semantic-accent-default
    );
    --mod-switch-control-border-color: var(
      --Alias-background-semantic-accent-default
    );
    --mod-switch-background-color-selected-default: var(
      --Alias-background-semantic-accent-default
    );
  }

  --mod-switch-control-disabled-border-color: var(
    --Alias-content-disabled-default
  );
  --mod-switch-handle-background-color-disabled: var(
    --Alias-content-disabled-default
  );
  --mod-switch-background-color-disabled: var(--Palette-gray-25);
  --mod-switch-background-color-selected-disabled: var(
    --Alias-content-disabled-default
  );
  --mod-switch-handle-background-color-checked-disabled: var(--Palette-gray-25);
  --mod-switch-handle-border-color-disabled: var(
    --Alias-content-disabled-default
  );
  --mod-switch-label-color-disabled: var(--Alias-content-disabled-default);

  // sp-dialog-base

  sp-dialog-base {
    z-index: -1;
  }

  sp-dialog-base[open] {
    z-index: 999999;
  }
}
