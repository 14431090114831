.dialog {
  width: var(--mod-firefly-onboarding-dialog-width, 797px);
  height: var(--mod-firefly-onboarding-dialog-height, 525px);
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  background-color: var(--Alias-background-app-frame-elevated);
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.dialog img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  padding: 0 44px;

  h2 {
    color: var(--Alias-content-typography-heading);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  p {
    color: var(--Alias-content-typography-body);
    font-size: var(--mod-firefly-dialog-body-font-size, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  h2,
  p {
    margin: unset;
    padding: unset;
  }

  sp-button {
    width: var(--mod-firefly-onboarding-dialog-confirm-btn-width, 144px);
  }
}

.close-button {
  --mod-actionbutton-icon-size: var(--close-button-icon-size, 12px);
  --mod-actionbutton-background-color-default: transparent;

  position: absolute;
  top: 8px;
  right: 8px;
  height: var(--close-button-size, 32px);
  width: var(--close-button-size, 32px);
  border-radius: var(--close-button-border-radius, 50%);
  border-color: transparent;
}
