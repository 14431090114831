@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spectrum-global-dimension-size-125, 10px);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.views-card {
  height: $views-card--Height;
  width: $views-card--Width;
  border-radius: var(--spectrum-global-dimension-size-125, 10px);
  border: 1px solid #e6e6e6;
  overflow: hidden;
}

.views-card-image {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
