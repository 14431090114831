.panel {
  overflow: hidden;
  gap: 0;
}

.header {
  width: 100%;
  overflow: hidden;
}

.content {
  box-sizing: border-box;
  position: relative;
  flex: 1;
  overflow: hidden;
  padding: 0 12px 12px 12px;
}

.controls {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  gap: 2px;
  padding: 16px 12px 4px 12px;
}

.left-controls {
  display: flex;
  gap: 2px;
}

.chevron-icon {
  width: 12px;
}

.list {
  box-sizing: border-box;
  height: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: auto;
  margin: unset;
  padding: 8px;
  border-radius: 12px;
  border: 1px solid var(--Palette-gray-100);
  background: var(--Palette-gray-25);
}
