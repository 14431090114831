@import 'styles/variables';

.position-relative {
  position: relative;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.inline-flex {
  display: inline-flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.gap-xxs {
  gap: var(--spectrum-global-dimension-size-50);
}

.gap-xs {
  gap: var(--spectrum-global-dimension-size-100);
}

.gap-s {
  gap: var(--spectrum-global-dimension-size-125);
}

.gap-sm {
  gap: 13px;
}

.gap-m {
  gap: var(--spectrum-global-dimension-size-200);
}

.gap-l {
  gap: var(--spectrum-global-dimension-size-300);
}

.gap-xl {
  gap: var(--spectrum-global-dimension-size-500);
}

.w-max {
  width: max-content;
}

.w-full {
  width: 100%;
}

.w-auto {
  width: auto;
}

.h-full {
  height: 100%;
}

.picker-min-width {
  min-width: 176px;
}

.styled-popover {
  border-radius: var(--spectrum-corner-radius-200, 8px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(241, 241, 241, 0.75);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
  padding: var(--spectrum-global-dimension-size-200, 16px) 0 0 0;
}

.action-btn-custom {
  border: none;
  --spectrum-actionbutton-background-color-default: var(
    --spectrum-global-color-gray-200
  );
  --spectrum-actionbutton-background-color-hover: var(
    --spectrum-global-color-gray-300
  );
}

.action-btn-m {
  height: #{$medium--Action-Button--Size};
  width: #{$medium--Action-Button--Size};
  border-radius: var(--spectrum-alias-border-radius-medium, 8px);
}

.icon-color-content-neutral-default {
  color: var(--spectrum-neutral-content-color-default, inherit);
}

.icon-m {
  height: #{$medium--icon-m--Size};
  width: #{$medium--icon-m--Size};
}

.icon-s {
  height: 16px;
  width: 16px;
}

.slider-number-field-border {
  --system-spectrum-textfield-border-color: var(
    --spectrum-global-color-gray-300
  );
}

.cursor-pointer {
  cursor: pointer;
}

.menu-non-selected-offset {
  --mod-menu-item-selectable-edge-to-text-not-selected: 32px;

  ue-menu-item:not([selected]),
  ue-menu-item:not([selected]).non-selected-offset {
    padding-inline-start: var(
      --mod-menu-item-selectable-edge-to-text-not-selected
    );
  }
}

.tabs-container {
  padding: 0 12px;
}

// TODO create reusable component
.tabs {
  background-color: var(--Alias-background-app-frame-layer-1, #f8f8f8);
}

.tab-panel {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.tab-panel-content {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  padding: var(--panel-card-padding, 0 12px 12px 12px);
  width: 100%;
  overflow-y: auto;
}

.vertical-divider {
  align-self: stretch;
  height: auto;
  margin: 8px 0;
}

.absolute-align-horizontal {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.no-pointer-events * {
  pointer-events: none;
}

.panel-accordion-label-button {
  position: absolute;
  top: var(--mod-panel-accordion-label-button-top, 0);
  left: var(--mod-panel-accordion-label-button-left, 4px);
}

.object-properties-text-number-field-width {
  inline-size: var(--mod-object-properties-text-number-field-width, 80px);
}

.object-properties-repeat-copy-number-field-width {
  width: var(--mod-object-properties-repeat-copy-number-field-width, 56px);
}

.menu-item-with-input-value {
  --mod-menu-item-bottom-edge-to-text: 0;
  --mod-menu-item-top-edge-to-text: 0;
}

.disabled-menu-item-with-tooltip {
  pointer-events: all;
}

.firefly-upgrade-button {
  width: fit-content;
  padding-top: 2px;
  border: unset;
  background: linear-gradient(
    95deg,
    #b539c8 3.03%,
    #7155fa 65.32%,
    #3b63fb 97.41%
  );
}
