@import 'styles/variables';

$dialog-size--Height: 570px;
$dialog-size--Width: 635px;
$dialog-side-nav--Width: 216px;
$dialog-content--Width: 350px;
$dialog-header--Height: 50px;

.share-menu-popover {
  --mod-popover-offset: -7px;
  --menu-width: 276px;

  width: var(--menu-width);
  position: relative;
  padding: 14px 0 14px 0;

  ue-menu {
    width: var(--menu-width);
  }
}

.share-menu-popover h4 {
  margin: unset;
  padding: 0px 12px 8px 12px;
  font-size: var(--mod-studio-app-bar-body-font-size, 14px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.share-menu {
  --mod-menu-item-label-content-color-default: var(
    --spectrum-global-color-gray-800
  );
  --mod-menu-item-description-color-default: var(
    --spectrum-global-color-gray-700
  );

  ue-menu-item sp-icon {
    height: 20px;
    width: 20px;
    align-self: center;
  }
}

.share-menu-popover-large {
  --menu-width: 310px;
}

.side-nav-container {
  width: $dialog-side-nav--Width;
  padding: var(--spectrum-global-dimension-size-300, 24px)
    var(--spectrum-global-dimension-size-250, 20px)
    var(--spectrum-global-dimension-size-300, 24px)
    var(--spectrum-global-dimension-size-250, 20px);
}

.side-nav {
  display: flex;
  flex-direction: column;
  gap: var(--spectrum-global-dimension-size-125);
}

.side-nav-items {
  display: flex;
  flex-direction: column;
  gap: var(--spectrum-global-dimension-size-125);
}

.side-nav-item {
  border-radius: 4px;

  &__selected {
    background-color: var(--spectrum-menu-item-background-color-hover);
  }

  &__wrapper {
    display: flex;
    gap: var(--spectrum-global-dimension-size-100);
  }
}

.export-dialog {
  height: $dialog-size--Height;
  width: $dialog-size--Width;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: var(--spectrum-global-color-gray-50);
    border-radius: var(--spectrum-global-dimension-size-125, 10px);
  }

  &__header {
    display: flex;
    align-items: center;
    min-height: $dialog-header--Height;
    background-color: var(--spectrum-global-color-gray-100);
    border-bottom: 2px solid var(--spectrum-global-color-gray-200);
    padding-left: var(--spectrum-global-dimension-size-275, 22px);
    border-radius: var(--spectrum-global-dimension-size-125, 10px)
      var(--spectrum-global-dimension-size-125, 10px) 0 0;
  }

  &__body {
    display: flex;
    gap: var(--spectrum-global-dimension-size-125, 10px);
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spectrum-global-dimension-size-85, 7px);
  }
}

.dialog-base-hidden {
  opacity: 0;
}

.toast {
  position: fixed;
  bottom: var(--toast-bottom-position);
  left: calc(50% - 320px / 2);
  transform: translateX(-50%);
}
