@import 'styles/variables';
$action-btn-wrapper--Width: 180px;
$navigation-button-bg: #2c2c2c;
$navigation-button-hover-bg: #323232;
$rounded-radius: 25%;
$separator-color: var(--spectrum-global-color-gray-300);

.wrapper__horizontal {
  display: flex;
  gap: 0px;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}

.wrapper__vertical {
  display: flex;
  align-items: center;
  gap: 0px;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: rotate(-90deg) translateX(-50%);
  height: var(--spectrum-actionbutton-min-width, 32px);
  transform-origin: 0 0;
  z-index: 9;
}

.vertical-orbit-slider-tooltip-container {
  z-index: 9;
  position: absolute;
  top: 50%;
  left: calc(
    var(--spectrum-global-dimension-size-150, 12px) + 24px +
      var(--spectrum-global-dimension-size-150, 12px)
  );
  pointer-events: none;
  transform: translateY(-50%) translateX(0%);
  opacity: 1;
  transition: transform var(--spectrum-overlay-animation-duration, 100ms)
      ease-in-out,
    opacity var(--spectrum-overlay-animation-duration, 100ms) ease-in-out,
    visibility 0s linear var(--spectrum-overlay-animation-duration, 100ms);
}

.vertical-orbit-slider-tooltip-container-invisible {
  opacity: 0;
  transform: translateY(-50%) translateX(-50%);
}

.action-btns--left {
  display: flex;
  gap: var(--spectrum-global-dimension-size-150, 12px);
  justify-content: flex-end;
  padding-right: 8px;
}

.camera-frame-menu {
  --mod-actionbutton-background-color-default: #2c2c2c;
  --mod-actionbutton-background-color-hover: #323232;
  --mod-actionbutton-content-color-default: #dbdbdb;
  --mod-actionbutton-content-color-hover: #dbdbdb;

  --mod-actionbutton-background-color-default-selected: #dbdbdb;
  --mod-actionbutton-content-color-default-selected: #111;

  ue-menu-item {
    min-width: 193px;
  }

  ue-switch {
    --mod-switch-control-label-spacing: 0;
  }
}

.action-btn {
  padding: 0;
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #2b2b2b;
    border: 2px solid #424242;
    sp-icon svg path {
      fill: #fff;
    }
  }
  &:hover {
    background-color: transparent;
  }
}

.action-menu {
  --mod-actionbutton-border-radius: 50%;
}

.icon_container_rotated_0_deg {
  transform: translateY(2px);
}

.icon_container_rotated_180_deg {
  transform: rotate(-180deg) translateY(2px);
}

.navigation-btn-rounded-left {
  padding-left: 0px;
  padding-right: 0px;
  border-radius: $rounded-radius 0% 0% $rounded-radius;
  border: 1px;
  background-color: $navigation-button-bg;
  border-color: #2e2e2e;
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    sp-icon svg path {
      fill: #fff;
    }
  }
  &:hover {
    background-color: $navigation-button-hover-bg;
  }
}

.navigation-btn-rounded-right {
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 0% $rounded-radius $rounded-radius 0%;
  border: 1px;
  background-color: $navigation-button-bg;
  border-color: #2e2e2e;
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    sp-icon svg path {
      fill: #fff;
    }
  }
  &:hover {
    background-color: $navigation-button-hover-bg;
  }
}

.navigation-btn-rounded-down {
  padding-left: 0px;
  padding-right: 0px;
  border-radius: $rounded-radius 0% 0% $rounded-radius;
  border: 1px;
  border-color: #2e2e2e;
  background-color: $navigation-button-bg;
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    sp-icon svg path {
      fill: #fff;
    }
  }
  &:hover {
    background-color: $navigation-button-hover-bg;
  }
}

.navigation-btn-rounded-up {
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 0% $rounded-radius $rounded-radius 0%;
  border: 1px;
  border-color: #2e2e2e;
  background-color: $navigation-button-bg;
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    sp-icon svg path {
      fill: #fff;
    }
  }
  &:hover {
    background-color: $navigation-button-hover-bg;
  }
}

.orbit-button-separator-container {
  background-color: $navigation-button-bg;
  width: 2px;
  height: var(--mod-orbit-controls-size, 32px);
}

.orbit-button-separator {
  position: absolute;
  top: 4px;
  background-color: $separator-color;
  width: 2px;
  height: calc(var(--mod-orbit-controls-size, 32px) - 8px);
}

.lock-camera-button-container {
  margin-left: 8px;
}

.action-btn {
  color: #dbdbdb;
  background-color: #2c2c2c;

  &:hover {
    background-color: #323232;
  }
}

.action-btn[selected] {
  color: #111;
  background-color: #dbdbdb;

  &:hover {
    background-color: #f2f2f2;
  }
}
