@import '../variables.scss';

.prompt-contextual-menu {
  display: none;
  animation: contextual-menu-vanish 0.2s ease-out forwards;
  position: absolute;
  bottom: var(--spectrum-global-dimension-size-250, 20px);
  z-index: 9997;
  left: 50%;
  background-color: var(--spectrum-background-layer-2-color);
  border-radius: 16px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
  padding: 12px 16px 12px 16px;
  height: var(--studio-firefly-contextual-menu-height);
  width: calc(100% - 24px - 24px);
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
}

.prompt-contextual-menu-visible {
  display: block;
  animation: contextual-menu-appear 0.2s cubic-bezier(0.34, 1.56, 0.64, 1)
    forwards;
}

@keyframes contextual-menu-appear {
  from {
    opacity: 0;
    transform: translate(
      -50%,
      calc(
        var(--spectrum-global-dimension-size-250, 20px) +
          var(--studio-firefly-contextual-menu-height)
      )
    );
  }

  to {
    opacity: 1;
    transform: translate(-50%, 0%);
  }
}

@keyframes contextual-menu-vanish {
  from {
    display: block;
    transform: translate(-50%, 0%);
  }

  to {
    display: none;
    transform: translate(
      -50%,
      calc(var(--spectrum-global-dimension-size-250, 20px) + 267px)
    );
  }
}

.input-wrapper {
  display: flex;
  flex-direction: column;

  label {
    margin: unset;
    font-size: var(--mod-firefly-context-bar-input-label-font-size, 14px);
    font-weight: 400;
    line-height: calc(
      var(--mod-firefly-context-bar-input-label-font-size, 14px) + 4px
    );
    text-align: left;
    color: #afafaf;
  }
}

.prompt-contextual-menu-action-group {
  display: flex;
  gap: var(--spectrum-global-dimension-size-100, 8px);
  align-items: end;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.cancel-btn {
  position: absolute;
  top: 8px;
  right: 8px;
}

.content {
  margin-top: 12px;
  padding: 4px 0;
  display: flex;
  justify-content: space-between;
}

.image-container {
  display: flex;
  gap: 8px;
  position: absolute;
  bottom: calc(var(--studio-firefly-contextual-menu-height) + 14px);
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
}

.image-wrapper {
  height: var(--studio-firefly-generated-image-height);
  width: var(--studio-firefly-generated-image-height);
  flex-shrink: 0;
  border: 2px solid transparent;
  border-radius: var(--spectrum-global-dimension-size-100, 8px);
  overflow: hidden;
}

.selected-image {
  border-radius: 8px;
  border: 2px solid #345bf8;
}

.divider {
  height: 24px;
}

.sp-textfield {
  --mod-textfield-border-width: 0;
  width: 100%;
  --mod-textfield-text-color-default: var(--spectrum-global-color-gray-800);
}

.textfield {
  height: 37px;
  width: calc(100% - 16px);
  padding: unset;
  border-color: transparent;
  border-radius: 4px;
  font-family: inherit;
  font-size: 16px;
  outline-offset: 2px;
  outline-color: var(--Alias-focus-indicator-default);
  outline-width: 2px;
  background-color: var(--spectrum-background-layer-2-color);

  &::placeholder {
    color: var(--Palette-gray-800);
    font-style: italic;
  }
}

.toast {
  z-index: 9997;
  position: absolute;
  left: calc(50%);
  // Had to vertically position with translateY since for some reason positioning with bottom or top doesn't work.
  transform: translateX(-50%) translateY(34px);
}

.toast-adjusted-firefly-popover {
  bottom: 200px;
}

.denied-prompt-words-toast {
  overflow: hidden;

  --mod-toast-corner-radius: 10px;
  --mod-toast-block-size: unset;
  --mod-toast-max-inline-size: 450px;
  --mod-toast-spacing-start-edge-to-text-and-icon: 0;
  --mod-toast-spacing-block-start: 0;
  --mod-toast-spacing-block-end: 0;
  --mod-closebutton-align-self: center;

  sp-close-button {
    align-self: center;
  }
}

.denied-prompt-words-toast-content {
  display: flex;
  gap: 10px;

  img {
    height: 120px;
    width: 120px;
  }
}

.denied-prompt-words-toast-content-msg {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  h4,
  p {
    margin: unset;
    padding: unset;
  }

  p {
    line-height: 120%;
  }

  sp-button {
    margin-top: -8px;
    color: #292929;
    background-color: #fff;
    align-self: flex-end;
  }
}

.generate-btn-pending {
  width: 75px;
  display: flex;
  justify-content: center;
}

.terms-of-service {
  display: none;
  animation: vanish 0.2s ease-out forwards;
  position: absolute;
  overflow: hidden;
  z-index: 10000;
  bottom: calc(var(--spectrum-global-dimension-size-250, 20px) + 160px);
  left: 50%;
  height: auto;
  width: 700px;
  background-color: var(--spectrum-background-layer-2-color);
  border-radius: 16px;
  box-shadow: 0px 4px 16px 0px var(--Palette-transparent-black-300);
  color: var(--Alias-content-typography-body);
  padding: 55px 32px 32px 32px;

  img {
    position: absolute;
    top: -154px;
    left: 0;
    width: 100%;
    clip-path: inset(75% 0 -20% 0);
  }

  h3,
  p,
  a {
    margin: unset;
    padding: unset;
  }

  h3 {
    color: var(--Alias-content-typography-heading);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    padding: 16px 0 24px 0;
  }

  p,
  a {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  p {
    color: var(--Alias-content-typography-body);
  }

  a {
    color: var(--Alias-background-semantic-accent-default);
  }
}

.terms-of-service-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.terms-of-service-visible {
  display: block;
  animation: appear 0.25s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translate(
      -50%,
      calc(
        var(--spectrum-global-dimension-size-250, 20px) +
          var(--studio-firefly-contextual-menu-height)
      )
    );
  }

  to {
    opacity: 1;
    transform: translate(-50%, 0%);
  }
}

@keyframes vanish {
  from {
    display: block;
    opacity: 1;
    transform: translate(-50%, 0%);
  }

  to {
    display: none;
    opacity: 0;
    transform: translate(
      -50%,
      calc(
        var(--spectrum-global-dimension-size-250, 20px) +
          var(--studio-firefly-contextual-menu-height)
      )
    );
  }
}
