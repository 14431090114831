.container {
  min-block-size: var(--badge-height);
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: var(--badge-border-radius);
  background: var(--badge-background, #d7f7e1);
  padding: 0 9px;
  color: var(--Palette-gray-1000, var(--Palette-gray-1000, #000));
  font-size: var(--badge-font-size);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.container[data-size='s'] {
  --badge-height: var(--spectrum-component-height-75);
  --badge-border-radius: 7px;
  --badge-font-size: var(--spectrum-font-size-75);
}

.container[data-size='m'] {
  --badge-height: var(--spectrum-component-height-100);
  --badge-border-radius: 8px;
  --badge-font-size: var(--spectrum-font-size-100);
}

.container[data-size='l'] {
  --badge-height: var(--spectrum-component-height-200);
  --badge-border-radius: 9px;
  --badge-font-size: var(--spectrum-font-size-200);
}

.container[data-fill='subtle'][data-variant='positive'] {
  --badge-background: var(
    --Alias-background-semantic-positive-subtle-default,
    #d7f7e1
  );
}

.container[data-fill='subtle'][data-variant='notice'] {
  --badge-background: var(
    --Alias-background-semantic-notice-subtle-default,
    #ffeccf
  );
}
