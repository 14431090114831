.wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  user-select: none;
  outline: 0;
  width: 100%;
}

.action-menu {
  display: none;
  display: hidden;
  position: absolute;
  top: 4px;
  right: 4px;
}

.container:hover .action-menu {
  display: block;
}

.thumbnail {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
  background-color: var(--Palette-gray-100);
  border-width: 2px;
  border-radius: 12px;
  border-style: solid;
  border-color: var(--Palette-gray-100);
}

.selected {
  border-color: var(--Alias-content-semantic-accent-selected);
}

.label {
  margin-left: 4px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
