.popover {
  width: 380px;
  border-radius: 10px;
  background-color: var(--Palette-gray-75);
  display: grid;
  grid-template-rows: 220px auto;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-container {
  grid-row-start: 2;
  grid-row-end: 3;
  padding: 24px;
}

.text {
  padding: 0;
  margin: 0;
  color: var(--Palette-gray-900);
  font-size: 16px;
}

.header {
  font-weight: 700;
  line-height: 20.8px;
}

.body {
  margin-top: 12px;
  font-weight: 400;
  line-height: 24px;
}

.button-container {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
