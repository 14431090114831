@import 'styles/variables';

.container {
  background-color: rgb(255, 255, 255);
  min-height: var(--spectrum-global-dimension-size-400, 32px);
  font-size: 11px;
  color: rgb(103, 118, 129);
  padding-bottom: 68px;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  justify-content: center;
  align-items: center;

  span {
    margin-right: var(--spectrum-global-dimension-size-400, 32px);
  }
}

.adobe-logo {
  margin-right: 64px;
}

.links {
  list-style: none;
  display: flex;
  padding-inline-start: 0;
  margin: 0;
}

.link {
  margin-right: var(--spectrum-global-dimension-size-400, 32px);
  line-height: var(--spectrum-global-dimension-size-200, 16px);
}

@media (max-width: $breakpoint-medium) {
  .container {
    flex-direction: column;
    height: auto;
    width: auto;
    -webkit-box-align: start;
    align-items: start;
    padding-bottom: 100px;
    margin: unset;
    align-items: center;
  }

  .adobe-logo {
    margin-right: unset;
    margin-bottom: var(--spectrum-global-dimension-size-300, 24px);
  }

  .links {
    flex-direction: column;
  }
}
