.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: var(--icons-focus-radius, 12px);
  cursor: pointer;
}

.thumbnail-container {
  background: var(--spectrum-global-color-gray-200);
  border-radius: var(--icons-focus-radius, 12px);
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.selected {
  box-shadow: 0px 0px 0px 3px var(--Alias-content-semantic-accent-selected);
  outline: 1px solid var(--spectrum-global-color-gray-50);
}

.thumbnail {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.label {
  color: var(--spectrum-global-color-gray-800);
  font-size: 12px;
  font-weight: var(--spectrum-global-font-weight-regular, 400);
  line-height: 150%;
}

.close-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--spectrum-global-dimension-size-300, 24px);
  width: var(--spectrum-global-dimension-size-300, 24px);
  display: none;
  position: absolute;
  top: var(--spectrum-global-dimension-size-50, 4px);
  right: var(--spectrum-global-dimension-size-50, 4px);
  border-radius: 7px;
  background: var(--spectrum-global-color-gray-800);
  color: var(--spectrum-global-color-gray-50);
  cursor: pointer;
}

.container:hover .close-icon {
  cursor: pointer;
  display: flex;
}

.checkmark-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--spectrum-global-dimension-size-200, 16px);
  width: var(--spectrum-global-dimension-size-200, 16px);
  position: absolute;
  top: var(--spectrum-global-dimension-size-50, 4px);
  right: var(--spectrum-global-dimension-size-50, 4px);
  border-radius: var(--spectrum-corner-radius-100, 4px);
  background: var(--Alias-content-semantic-accent-selected);

  svg {
    color: var(--Palette-gray-25);
  }
}
