.trigger {
  --mod-actionbutton-edge-to-visual-only: 10px;

  box-sizing: border-box;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.thumbnail {
  height: 18px;
  width: 18px;
  object-fit: contain;
  border-radius: 4px;
}

.trigger[size='l'] {
  .thumbnail {
    height: 26px;
    width: 26px;
  }
}

.trigger-label {
  display: flex;
  align-items: center;
  gap: 6px;
}

.popover {
  --mod-popover-offset: -7px;

  position: relative;
  width: 160px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: var(--Alias-background-app-frame-elevated);
  border-radius: 10px;
}

.popover-cjk {
  width: 193px;
}

.menu-item {
  cursor: pointer;
  position: relative;
  height: 56px;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--Alias-content-neutral-default);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 10px;

  img {
    height: 40px;
    width: 40px;
    object-fit: contain;
    border-radius: 8px;
  }

  &:hover {
    background: var(--Palette-gray-100);
  }
}

.selected {
  background: var(--Palette-gray-100);
}

.more-shapes {
  justify-content: space-between;

  sp-icon {
    width: 12px;
    height: 12px;
  }
}
