@import 'styles/variables';

.popover {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  max-width: 320px;
}

.popover-large {
  max-width: 360px;
}

.hidden {
  display: none;
}

.toast {
  position: fixed;
  bottom: var(--toast-bottom-position);
  left: calc(50% - 320px / 2);
  transform: translateX(-50%);
}
