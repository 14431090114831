.container {
  display: flex;
  align-items: center;
  font-size: var(--spectrum-font-size-200, 16px);
  color: var(--spectrum-global-color-gray-800);
  white-space: nowrap;
}

.clickable {
  cursor: pointer;
}

.app-name {
  display: flex;
  align-items: center;
  line-height: 32px;
  margin-left: var(--spectrum-global-dimension-size-100, 8px);
}

.adobe-name {
  font-weight: var(--spectrum-global-font-weight-extra-bold);
}

.beta-icon {
  margin-left: var(--spectrum-global-dimension-size-100, 8px);
}

.badge-wrapper {
  display: flex;
  align-items: center;
  gap: var(--spectrum-global-dimension-size-100, 8px);
}
