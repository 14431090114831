.material-picker-container {
  display: flex;
  justify-content: center;
  padding: var(--spectrum-global-dimension-size-100, 8px) 0;
}

.bottom-content {
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
}

.container {
  --mod-actionbutton-background-color-default-selected: var(
    --Alias-background-semantic-accent-default
  );

  ue-menu-item {
    border-radius: 8px;
  }
}
