.three-d-shape-menu-popover {
  --mod-popover-offset: -7px;

  position: relative;
  width: 180px;
  padding: 8px;
  background: var(--Alias-background-app-frame-elevated);
  border-radius: 10px;
}

.menu-item {
  cursor: pointer;
  position: relative;
  height: 32px;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--Alias-content-neutral-default);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;

  &:hover {
    background: var(--Palette-gray-100);
  }

  sp-icon {
    width: 18px;
    height: 18px;
  }
}

.more-shapes {
  justify-content: space-between;

  sp-icon {
    width: 12px;
    height: 12px;
  }
}
