.container {
  height: 100%;
  padding: var(--spectrum-global-dimension-size-0)
    var(--spectrum-global-dimension-size-200);
  overflow: auto;
}

.heading {
  margin: var(--spectrum-global-dimension-size-200)
    var(--spectrum-global-dimension-size-100);
}

.status-light {
  margin: var(--spectrum-global-dimension-size-125) 0;
}

.meeting-container {
  display: flex;
  flex-direction: column;
  gap: var(--spectrum-global-dimension-size-200);
  margin-bottom: var(--spectrum-global-dimension-size-200);
}
