.status {
  position: absolute;
  bottom: var(--spectrum-global-dimension-size-50);
  left: var(--spectrum-global-dimension-size-50);
  color: #fff;
  display: flex;
  align-items: center;
  gap: var(--spectrum-global-dimension-size-225);
  padding: var(--spectrum-global-dimension-size-65)
    var(--spectrum-global-dimension-size-125);
  border-radius: var(--spectrum-global-dimension-size-200);
}

.container {
  position: relative;
  overflow: hidden;
  height: 169px;
  width: 100%;
  border-radius: var(--spectrum-global-dimension-size-200);
}

.name-container {
  position: absolute;
  top: var(--spectrum-global-dimension-size-50);
  left: var(--spectrum-global-dimension-size-50);
  padding: var(--spectrum-global-dimension-size-65)
    var(--spectrum-global-dimension-size-125);
  border-radius: var(--spectrum-global-dimension-size-200);

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

.name {
  max-width: 115px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
}

.volume-indicator {
  position: absolute;
  top: var(--spectrum-global-dimension-size-0);
  left: var(--spectrum-global-dimension-size-0);
  height: 100%;
  width: 100%;
  border-width: var(--spectrum-global-dimension-size-40);
  border-style: solid;
  border-radius: var(--spectrum-global-dimension-size-200);
}

.icon-toggle-off {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: var(--spectrum-global-dimension-size-85);
    left: -var(--spectrum-global-dimension-size-50);
    height: var(--spectrum-global-dimension-size-10);
    width: 21px;
    background: #fff;
    transform: rotate(44deg);
  }
}
