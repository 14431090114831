.wrapper {
  position: absolute;
  top: 100px;
  left: 200px;
  z-index: 99;

  --mod-object-properties-text-number-field-width: 160px;
}

.docked {
  position: absolute;
  top: calc(100% - 64px);
  left: 50%;
  transform: translateX(-50%);
}

.container {
  box-sizing: border-box;
  position: relative;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: var(--spectrum-global-dimension-size-125, 10px);
  background-color: var(--Alias-background-app-frame-layer-2);
  border-radius: 10px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
  z-index: 999;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spectrum-global-dimension-size-125, 10px);

  sp-action-button {
    font-weight: 700;
  }
}

.grab-handle {
  width: 4px;
  height: 24px;
  border-radius: 25px;
  background-color: var(--Palette-gray-200);
  cursor: grab;
}

.grabbing {
  cursor: grabbing;
}

.divider {
  align-self: stretch;
  height: auto;
  margin: 4px 0;
}

.more-menu {
  --swc-menu-width: 211px;
}
