.picker {
  --spectrum-alias-single-line-width: 100%;
  --spectrum-alias-input-border-color-default: var(
    --mod-textfield-border-color
  );

  af-font-picker {
    width: 220px;
  }
}
