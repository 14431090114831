.container {
  display: none;
  animation: vanish 0.1s linear forwards;
  position: absolute;
  bottom: calc(16px + var(--studio-firefly-contextual-menu-height) + 14px);
  z-index: 9997;
  left: 50%;
  background-color: #6d6d6d;
  border-radius: 16px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
  height: auto;
  width: auto;
  border-radius: 16px;
  color: #fff;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin: 0;
    padding: 0;
  }
}

.container-visible {
  display: block;
  animation: appear 0.2s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}

.free-exhaustable-content {
  padding: 12px 16px 12px 16px;
  display: flex;
  align-items: center;
  width: max-content;
  justify-content: space-between;
  height: auto;

  .text-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 850px;
  }

  p {
    margin-right: 24px;
  }

  .cta-button {
    align-self: center;
  }

  .close-button {
    margin-left: 12px;
  }
}

.paid-exhaustable-content {
  padding: 6px 8px 12px 16px;
  display: flex;
  gap: 10px;
  width: 672px;
}

.paid-exhaustable-content-left-side {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.close-button {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  min-width: 40px;
  background-color: rgba(109, 109, 109, 1);
  border: unset;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: rgba(94, 94, 94, 0.5);
  }
}

.cta-button {
  align-self: flex-end;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translate(
      -50%,
      calc(16px + var(--studio-firefly-contextual-menu-height))
    );
  }

  to {
    opacity: 1;
    transform: translate(-50%, 0%);
  }
}

@keyframes vanish {
  from {
    display: block;
    transform: translate(-50%, 0%);
  }

  to {
    display: none;
    transform: translate(-50%, 16px);
  }
}
