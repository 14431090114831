.rail {
  box-sizing: border-box;
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 999;
  overflow-y: auto;
  background-color: var(--spectrum-global-color-gray-50);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
}
