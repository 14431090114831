*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  overscroll-behavior: none;
}

a {
  color: inherit;
  text-decoration: none;
}
