.container {
  --close-button-icon-size: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: var(--panel-header-padding, 14px 5px 0 12px);
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text {
  flex: 1;
}

.back-button {
  --mod-actionbutton-background-color-default: transparent;

  border-radius: 50%;
  border-color: transparent;
}
