.panel-container {
  box-sizing: border-box;
  position: relative;
  height: 100%;
  overflow: auto;
  padding: var(--spectrum-global-dimension-size-300, 24px)
    var(--spectrum-global-dimension-size-150, 12px);
  display: flex;
  flex-direction: column;
  gap: var(--spectrum-global-dimension-size-300, 24px);
}
