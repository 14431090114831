$avatar-group--Height: var(--spectrum-global-dimension-size-500);
$avatar-group--Width: var(--spectrum-global-dimension-size-500);

.visible-avatar-container {
  display: flex;
  align-items: center;
}

.remaining-avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--spectrum-global-color-gray-700);
  outline: '2px solid #fff';

  &__avatar-count {
    font-size: var(--spectrum-font-size-75);
    color: var(--spectrum-global-color-gray-200);
  }
}

.avatar-group__group-count {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #434343;
  border-radius: 50%;
  height: $avatar-group--Height;
  width: $avatar-group--Width;
  margin-left: var(--spectrum-global-dimension-size-85);
  color: #000;
}

@media (prefers-color-scheme: dark) {
  .avatar-group__group-count {
    background: #434343;
  }
}

@media (prefers-color-scheme: light) {
  .avatar-group__group-count {
    background: #fff;
  }
}
