.side-nav-item {
  border-radius: 8px;
  margin: unset;
  padding: 6px 12px 8px 12px;
  width: var(--side-navigation-item-width, 160px);
  color: var(--Alias-content-neutral-default);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;

  &:hover {
    background-color: var(--Palette-gray-100);
  }
}

.selected {
  background-color: var(--Palette-gray-100);

  &:hover {
    background-color: var(--Palette-gray-200);
  }
}
