.popover {
  width: 304px;
  padding: 16px 15px 16px 20px;

  p,
  sp-link {
    margin: unset;
    padding: unset;
    color: var(--Alias-content-typography-body, #000);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-wrap: pretty;
  }
}
