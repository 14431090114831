.container {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #d0d0d0;
  border-radius: var(--spectrum-global-dimension-size-200, 16px);
  animation: blink 2s infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
