canvas#canvas {
  height: 100%;
  width: 100%;
  outline: none;
  border-radius: inherit;
}

:root {
  --right-rail-width: 70px;
}
