.container {
  position: absolute;
  top: var(--spectrum-global-dimension-size-0);
  left: var(--spectrum-global-dimension-size-0);
  height: 100%;
  width: 100%;
  border: var(--spectrum-global-dimension-size-40) solid blue;
  background: transparent;
  z-index: 9;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;

  &--visible {
    opacity: 1;
  }

  &--hidden {
    opacity: 0;
  }
}

.user-following {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  height: 34px;
  width: auto;
  display: flex;
  align-items: center;
  gap: var(--spectrum-global-dimension-size-65);
  font-size: var(--spectrum-font-size-75);
  color: #fff;
  pointer-events: auto;
  border-radius: var(--spectrum-global-dimension-size-50);
  padding: var(--spectrum-global-dimension-size-0)
    var(--spectrum-global-dimension-size-125);
  padding: var(--spectrum-global-dimension-size-0)
    var(--spectrum-global-dimension-size-125);
  border: none;
  outline: inherit;
}
