@import 'styles/variables';
$slider--bg: var(--spectrum-global-color-gray-200);
$large-tick--bg: var(--spectrum-global-color-gray-700);
$small-tick--bg: var(--spectrum-global-color-gray-400);
$slider--Width: 77px;
.container {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  border: 0px solid #808080;
  border-radius: 0px;
  min-width: $slider--Width;
  max-width: $slider--Width;
}

.size-l {
  height: 40px;
}

.slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  background: transparent;
  padding: 0;
  min-width: $slider--Width;
  max-width: $slider--Width;
}

.slider::-webkit-slider-runnable-track {
  cursor: pointer;
  background: $slider--bg;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 32px;
  width: 20px;
}

.slider::moz-range-thumb {
  height: 32px;
  width: 20px;
}

.ticks {
  background-color: #2c2c2c;
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  width: 610%;
  .largeTick,
  .smallTick {
    pointer-events: none;
  }
  .largeTick {
    height: 12px;
    width: 2px;
    background-color: $large-tick--bg;
  }
  .smallTick {
    height: 8px;
    width: 2px;
    background-color: $small-tick--bg;
  }
}
