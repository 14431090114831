.layer-info {
  position: relative;
  width: calc(100% - 10px);
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 16px;
  padding: 0 12px;

  h6 {
    padding: unset;
    margin: unset;
    color: var(--Alias-content-typography-body);
    font-size: var(--mod-object-properties-layer-info-font-size, 14px);
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.layer-info-object {
  display: flex;
  width: var(--icons-size, 32px);
  height: var(--icons-size, 32px);
  padding: 3.2px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Palette-gray-100);
}

.multi-selected-description {
  padding: 0 12px;
}
