.button {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 10px;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 11px 5px 11px 8px;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid var(--Palette-blue-800);
  align-items: center;
  z-index: 999;
}

.button:hover {
  cursor: pointer;
}

.text {
  grid-column: 0;
  text-wrap: wrap;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  padding: 0;
  z-index: 0;
  margin: 0;
  color: var(--Palette-gray-1000);
}
