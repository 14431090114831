.menu-item {
  display: flex;
  column-gap: 6px;
  align-items: center;
}

.text-container {
  display: flex;
  flex-direction: column;
}

.text {
  font-size: 14px;
  color: var(--Palette-gray-900);
  line-height: 18px;
}

.subtext {
  font-size: 11px;
  color: var(--spectrum-gray-600);
}
