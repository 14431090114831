$avatar--Height: 1.5rem;
$avatar--Width: 1.5rem;

.cursor {
  position: fixed;
  top: var(--spectrum-global-dimension-size-0);
  left: var(--spectrum-global-dimension-size-0);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: var(--spectrum-global-dimension-size-115);

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative;
    width: fit-content;
    top: var(--spectrum-global-dimension-size-250);
    left: -17px;
    border-radius: 1.25rem;
    padding: 0.2rem;
    padding-right: 0.7rem;
    height: 1.9rem;
    gap: var(--spectrum-global-dimension-size-100);
  }

  &__avatar {
    height: $avatar--Height !important;
    width: $avatar--Width !important;
    object-fit: cover;
    border: var(--spectrum-global-dimension-size-10) solid #434343;
  }

  &__name {
    color: #fff;
    font-size: 0.9rem;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__received {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: row;
    position: relative;
    top: -10px;
    left: -17px;
  }
}
