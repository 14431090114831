@import 'styles/variables';

.links {
  list-style: none;
  display: flex;
  padding-inline-start: 0;
  margin: 0;
  align-items: center;

  &__column {
    flex-direction: column;
    list-style: none;
    padding-left: 0;
  }
}

.link {
  margin-right: var(--spectrum-global-dimension-size-400, 32px);
  line-height: var(--spectrum-global-dimension-size-200, 16px);

  &__column:first-child {
    margin-bottom: var(--spectrum-global-dimension-size-300, 24px);
  }

  &__column:not(:first-child) {
    margin-top: var(--spectrum-global-dimension-size-50, 4px);
    text-decoration: underline;
  }
}

@media (max-width: $breakpoint-medium) {
  .links {
    flex-direction: column;
    gap: var(--spectrum-global-dimension-size-125, 10px);
  }

  .link {
    margin-right: unset;
  }
}
